import { Pipe, PipeTransform } from "@angular/core";

@Pipe({ name: "servicefilter" })
export class NameFilterPipe implements PipeTransform {
  /**
   * Transform
   *
   * @param {any[]} items
   * @param {string} searchText
   * @param {string} property
   * @returns {any}
   */
  transform(items: any[], searchText: string, type: string): any[] {
    // return empty array if array is falsy
    if (!items) {
      return [];
    }

    // return the original array if search text is empty
    if (!searchText) {
      return items;
    }

    // retrun the filtered array
    return items.filter(courseOrEvent => {
      let name = type === "event" ? courseOrEvent.name.toLowerCase() : courseOrEvent.name.toLowerCase();
      return name.includes(searchText.toLowerCase());
    });
  }
}
