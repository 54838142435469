import { TenantUserService } from "app/core/services/tenantuser.service";
export class ClassModel {
  guId: string;
  name: string;
  imageUrl: string;
  videoUrl: string;
  location: string;
  isZoomMeeting: boolean;
  startTime: string;
  isFeaturedClass: boolean;
  isRepeat: boolean;
  classType: string;

  constructor(classObj, imgPrefix) {
    this.name = classObj.name;
    this.guId = classObj.guId;
    this.imageUrl = classObj.imageUrl ? imgPrefix + classObj.imageUrl : null;
    this.videoUrl = classObj.videoUrl;
    this.location = classObj.location;
    this.isZoomMeeting = classObj.isZoomMeeting;
    this.startTime = this.getStartTime(classObj.startTime);
    this.isFeaturedClass = classObj.isFeaturedClass;
    this.isRepeat = classObj.isRepeat;
    this.classType = this.getClassType(classObj);
  }

  getStartTime(startTime: any) {
    return TenantUserService.displayEventStartTime(startTime);
  }

  getClassType(classObj: any) {
    if (classObj.isFeaturedClass) {
      return "event";
    } else if (classObj.contact) {
      return "private";
    } else {
      return "class";
    }
  }
}
