import {Component, EventEmitter, Inject, OnInit, ViewEncapsulation} from "@angular/core";
import {FormBuilder, FormControl, FormGroup} from "@angular/forms";
import {MatDialogRef, MAT_DIALOG_DATA} from "@angular/material/dialog";
import {fuseAnimations} from "@fuse/animations";
import {FileObject} from "app/main/shared/components/file-upload/file-upload.component";
import {SubSink} from "subsink";
import {AppSettingsService} from "../../services/app-settings.service";
import {ToastrService} from "ngx-toastr";

@Component({
    selector: "external-website-seosetting-template-dlg",
    templateUrl: "./external-website-seosetting-template-dlg.component.html",
    styleUrls: ["./external-website-seosetting-template-dlg.component.scss"],
    animations: fuseAnimations,
    encapsulation: ViewEncapsulation.None
})
export class ExternalWebsiteSeosettingTemplateDlgComponent implements OnInit {
    templateForm: FormGroup;
    templateImage$: EventEmitter<FileObject[]> = new EventEmitter<FileObject[]>();
    orgId: string = "master";
    subs = new SubSink();
    seoForm: FormGroup = this._formBuilder.group({
        seoDescription: new FormControl(""),
        title: new FormControl(""),
    });

    constructor(
        public matDialogRef: MatDialogRef<ExternalWebsiteSeosettingTemplateDlgComponent>,
        @Inject(MAT_DIALOG_DATA) private _data: any,
        private _formBuilder: FormBuilder,
        private appSettingsService: AppSettingsService,
        public toastr: ToastrService,
    ) {
        this.orgId = _data.orgId;
    }

    ngOnInit() {
        this.subs.add(
            this.appSettingsService.getPluginIntTenant().subscribe(
                (details: any) => {
                    this.seoForm.patchValue({
                        seoDescription: details.seoDescription,
                        title: details.title,
                    });
                },
                (error) => {
                }
            )
        );

    }

    submitForm() {
        this.seoForm.markAsTouched();
        if (this.seoForm.valid) {
            this.subs.add(
                this.appSettingsService.updatePluginTenant(this.seoForm.value).subscribe(
                    (resp) => {
                        this.toastr.success("Settings updated successfully");
                    },
                    (errorResp) => {
                        this.toastr.error(errorResp.error.errors.message);
                    }
                )
            );
        }
        this.matDialogRef.close("returndata");
    }
}
