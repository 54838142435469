import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({name: 'epocToDate'})
export class epocToDatePipe implements PipeTransform
{
    /**
     * Transform
     *
     * @param {string} value
     * @param {any[]} format
     * @returns {string}
     */
    transform(value: number, format?: string): string
    {
        var day = moment.unix(value);
        return day.format(format || 'MM/DD/YYYY')
    }
}
