export class LessonModel {
  dateCreated: string;
  guId: string;
  isActive: boolean;
  isNote: boolean;
  isPublished: boolean;
  lastUpdated: string;
  notes: [];
  quiz: [];
  section: string;
  sequence: number;
  title: string;
  lessonType?: string;
  
  constructor(lesson: any) {
    this.dateCreated = lesson.dateCreated;
    this.guId = lesson.guId;
    this.isActive = lesson.isActive;
    this.isNote = lesson.isNote;
    this.isPublished = lesson.isPublished;
    this.lastUpdated = lesson.lastUpdated;
    this.notes = lesson.notes;
    this.quiz = lesson.quiz;
    this.section = lesson.section;
    this.sequence = lesson.sequence;
    this.title = lesson.title;
    this.lessonType = lesson.lessonType;
  }
}
