<div fxLayout="column" fxLayoutGap="0.5rem">
  <div *ngFor="let file of fileStatusArr" fxLayout="row" fxLayoutAlign="space-between center" style="padding: 1rem; border-radius: 5px; background: #eefaff; position: relative">
    <span style="width: 250px; text-overflow: ellipsis; overflow: hidden; white-space: nowrap">{{ file.filename }}</span>
    <mat-progress-spinner class="ml-8" color="accent" mode="determinate" [value]="file.progress" diameter="25"><span>a</span></mat-progress-spinner>
    <div class="absolute-container">
      <span class="material-symbols-outlined action-buttons" (click)="action(file)">
        {{ getStatus(file.status) }}
      </span>
    </div>
  </div>
  <div fxLayout="row" fxLayoutAlign="end center">
    <a mat-button class="clear-button" (click)="clear()">Clear</a>
  </div>
</div>
