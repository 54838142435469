import { HttpEventType } from "@angular/common/http";
import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from "@angular/core";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { DomSanitizer } from "@angular/platform-browser";
import { FuseConfirmDialogComponent } from "@fuse/components/confirm-dialog/confirm-dialog.component";
import { AccountDetail } from "app/main/admin/app-settings/models/account-details";
import { AppSettingsService } from "app/main/admin/app-settings/services/app-settings.service";
import { ServicesService } from "app/main/admin/services/services.service";
import { FileUploader } from "ng2-file-upload";
import { ToastrService } from "ngx-toastr";
import { SubSink } from "subsink";
import { FileUploadService } from "../../services/file-upload.service";
import { ConfirmationDialogComponent } from "../confirmation-dialog/confirmation-dialog.component";
import { FileObject } from "../file-upload/file-upload.component";
import { Cloudinary } from "@cloudinary/angular-5.x";
import { TenantUserService } from "app/core/services/tenantuser.service";
@Component({
  selector: "app-upload-file",
  templateUrl: "./upload-file.component.html",
  styleUrls: ["./upload-file.component.scss"]
})
export class UploadFileComponent implements OnInit, OnDestroy {
  private confirmDialogRef: MatDialogRef<FuseConfirmDialogComponent>;
  dialogRef: any;
  @Input() files: FileObject[] = [];
  @Input() title: string = "Add Files";
  @Input() description: string = "";
  @Input() buttonName: string = "Upload Files";
  @Input() folderName: string = "";
  @Input() moduleName: string = "";
  @Input() assestData: any = {};
  @Input() showPreview: boolean = true;
  @Input() isUploadDisabled: boolean = false;
  @Input() isInstantUpload: boolean = true;
  @Input() isMainUpload: boolean = false;
  @Input() isDeleteAllowed: boolean = true;
  @Input() isStudentFile: boolean = false;
  @Input() isMasterTenant: boolean = false;
  @Input() isFranchiseCourse: boolean = false;
  @Input() isNewlook: boolean = false;
  @Input() isMultipleUpload: boolean = false;
  @Output() getFileObject = new EventEmitter<any>();
  @Output() getCloudinaryUrl = new EventEmitter<any>();
  @ViewChild("videoPlayer") videoplayer: ElementRef;
  selectedFiles: FileList;
  uploadPercentage: number = 0;
  _tenantConfig: AccountDetail;
  subs = new SubSink();
  isLoading: boolean = false;
  isStudentRole: boolean = false;
  _imagePrefixPath: string;
  _baseUrl: string;
  _cloudinaryPreset: string;
  uploader: FileUploader;
  _cloudinaryImageUrl: string = "";

  constructor(
    private uploadService: FileUploadService,
    private sanitizer: DomSanitizer,
    private _changeRef: ChangeDetectorRef,
    private servicesService: ServicesService,
    public _matDialog: MatDialog,
    public toastr: ToastrService,
    private _settingsService: AppSettingsService,
    private _tenantUserService: TenantUserService
  ) {}

  ngOnInit() {
    //this.getUserObject();
    this._tenantConfig = this._settingsService.accountDetail;
    this._imagePrefixPath = "https://res.cloudinary.com/" + this._tenantConfig.cloudinaryCloudName;
    this._baseUrl = "https://api.cloudinary.com/v1_1/" + this._tenantConfig.cloudinaryCloudName + "/upload";
    this.initializeUploader();
  }
  getUserObject() {
    this.subs.add(
      this._tenantUserService.user.subscribe((user: any) => {
        this.isStudentRole = TenantUserService.isStudent(user);
      })
    );
  }

  initializeUploader() {
    this.uploader = new FileUploader({
      url: this._baseUrl,
      isHTML5: true,
      allowedFileType: ["image", "video"],
      removeAfterUpload: true,
      autoUpload: true,
      maxFileSize: 10 * 1024 * 1024,
      // XHR request headers
      headers: [
        {
          name: "X-Requested-With",
          value: "XMLHttpRequest"
        }
      ]
    });

    this.uploader.onBuildItemForm = (fileItem: any, form: FormData): any => {
      // Add Cloudinary unsigned upload preset to the upload form
      form.append("upload_preset", this._tenantConfig.cloudinaryPreset);

      // Add file to upload
      form.append("file", fileItem);

      form.append("folder", this._tenantConfig.orgId +'-'+ this._tenantConfig.tenantId);

      // Use default "withCredentials" value for CORS requests
      fileItem.withCredentials = false;
      return { fileItem, form };
    };

    this.uploader.onSuccessItem = (item, response, status, headers) => {
      if (response) {
        const res: FileObject = JSON.parse(response);
        const fileObject = {
          id: res.id,
          url: res.url
        };
        this._cloudinaryImageUrl = res.url;
        this.getCloudinaryUrl.emit({ url: res.url });
      }
    };
  }

  toggleVideo(event: any) {
    this.videoplayer.nativeElement.play();
  }

  selectFile(event) {
    this.selectedFiles = event.target.files;
    const file = this.selectedFiles.item(0);
    if (file) {
      this.isLoading = true;
      this.subs.add(
        //  Get S3 Signed URL
        this.uploadService
          .getUploadUrl(file, this.moduleName, this.folderName, file.type, this.assestData.isAddIntros)
          .subscribe(
            (result: any) => {
              this.uploadFile(result, file);
            },
            (error: any) => {
              this.isLoading = false;
              this.toastr.error(error.message);
            }
          )
      );
    }
  }

  uploadFile(result, file) {
    if (result) {
      //  Upload file to signed URL
      this.subs.add(
        this.uploadService.uploadFile(result.signedUrl, file, file.type).subscribe(
          (aws: any) => {
            if (aws.type == HttpEventType.UploadProgress) {
              this.uploadPercentage = (aws.loaded / aws.total) * 100;
            }
            if (aws.type == HttpEventType.Response) {
              this.uploadPercentage = 0;
              const payload = {
                ...this.assestData,
                fileType: this.getFileType(file.type),
                fileName: file.name
              };
              if (this.assestData.isAddIntros) {
                payload.fileName = this.assestData.fileName;
                payload.fileType = this.assestData.fileType;
              }
              if (payload.fileType == "logo") {
                this.uploader.addToQueue([file]);
                this.uploader.uploadAll();
              }

              if (this.isInstantUpload) {
                this.postAsset(payload, file);
              } else {
                this.isLoading = false;
                this.files.push({
                  id: 1,
                  url: this.sanitizer.bypassSecurityTrustResourceUrl(URL.createObjectURL(file)),
                  s3url: result.signedUrl,
                  downloadUrl: result.signedUrl ? result.signedUrl : result.s3url,
                  fileType: file.type,
                  name: file.name,
                  fileName: file.name,
                  status: "IN_PROGRESS"
                });
                this.getFileObject.emit({ files: this.files });
                this._changeRef.detectChanges();
              }
            }
          },
          (error: any) => {
            this.isLoading = false;
            console.log({ error });
            this.toastr.error("Error");
            // this.toastr.error(error.error.message);
          }
        )
      );
    }
  }

  postAsset(payload, file) {
    if (this.isMainUpload) {
      payload.sequence = 1;
    } else {
      payload.sequence = 0;
    }
    payload.isAddIntros = true;
    //  POST assest
    this.subs.add(
      this.uploadService.postAssest(payload).subscribe(
        (assest: any) => {
          if (assest.status == 500) {
            this.toastr.error(assest.msg);
          } else {
            // Get Assest
            this.getAssest(assest, file);
          }
          this.isLoading = false;
        },
        (error: any) => {
          this.isLoading = false;
          console.log({ error });
          this.toastr.error("Error");
          // this.toastr.error(error.error.message);
        }
      )
    );
  }

  getAssest(assest, file) {
    this.subs.add(
      this.uploadService.getAssest(assest.id).subscribe(
        (data: any) => {
          if (data.status == 500) {
            this.toastr.error(data.msg);
          } else {
            this.files.push({
              id: assest.id,
              url: data.s3url,
              s3url: data.s3url,
              fileType: file.type,
              fileName: data.fileName,
              downloadUrl: data.downloadUrl ? data.downloadUrl : data.s3Url,
              status: assest.status
            });
            this.getFileObject.emit({ files: this.files });
            this._changeRef.detectChanges();
          }
        },
        (error: any) => {
          this.isLoading = false;
          console.log({ error });
          this.toastr.error("Error");
          // this.toastr.error(error.error.message);
        }
      )
    );
  }

  isFileTypeMultimedia(fileType: string) {
    let extension = [
      "mp4",
      "mkv",
      "3gp",
      "x-flv",
      "MP2T",
      "3gpp",
      "quicktime",
      "x-msvideo",
      "x-ms-wmv",
      "x-mpegURL",
      "mov",
      "M4A",
      "FLAC",
      "MP3",
      "MP4",
      "WAV",
      "WMA",
      "AAC"
    ];
    return fileType ? extension.some(x => fileType.toLocaleLowerCase().includes(x.toLocaleLowerCase())) : false;
  }

  isFileTypeImage(fileType: string) {
    let extensions = ["jpg", "png", "gif", "webp", "tiff", "psd", "raw", "bmp", "jpeg"];
    return fileType ? extensions.some(x => fileType.toLocaleLowerCase().includes(x.toLocaleLowerCase())) : false;
  }

  isFileTypePDF(fileType: string) {
    return fileType ? fileType.toLocaleLowerCase().includes("pdf") : false;
  }

  isFileTypeOther(fileType: string) {
    if (fileType) {
      return !(this.isFileTypeMultimedia(fileType) || this.isFileTypeImage(fileType) || this.isFileTypePDF(fileType));
    } else {
      return false;
    }
  }

  isItemVideo(fileName: string) {
    let videoExtensions = [
      "mp4",
      "mkv",
      "3gp",
      "x-flv",
      "MP2T",
      "3gpp",
      "quicktime",
      "x-msvideo",
      "x-ms-wmv",
      "x-mpegURL",
      "mov",
      "m3u8"
    ];
    return fileName ? videoExtensions.some(x => fileName.toLocaleLowerCase().includes(x.toLocaleLowerCase())) : false;
  }

  isItemAudio(fileName: string) {
    let audioExtension = ["M4A", "FLAC", "MP3", "WAV", "WMA", "AAC"];
    return fileName ? audioExtension.some(x => fileName.toLocaleLowerCase().includes(x.toLocaleLowerCase())) : false;
  }

  getFileType(fileType) {
    switch (fileType) {
      case fileType.toLocaleLowerCase().includes("video"):
        return "video";
      default:
        return "file";
    }
  }

  getFileTypeForMultimedia(fileName) {
    if (fileName) {
      let extension = fileName.split(".");
      let videoEtensions = [
        "mp4",
        "mkv",
        "3gp",
        "x-flv",
        "MP2T",
        "3gpp",
        "quicktime",
        "x-msvideo",
        "x-ms-wmv",
        "x-mpegURL",
        "mov",
        "m3u8"
      ];
      let audioExtension = ["M4A", "FLAC", "MP3", "WAV", "WMA", "AAC"];
      if (fileName.toLocaleLowerCase().includes("m3u8")) {
        return `application/x-mpegURL`;
      }
      if (videoEtensions.some(x => fileName.toLocaleLowerCase().includes(x.toLocaleLowerCase()))) {
        return `video/mp4`;
      } else if (audioExtension.some(x => fileName.toLocaleLowerCase().includes(x.toLocaleLowerCase()))) {
        return `audio/mp3`;
      } else {
        return `video/mp4`;
      }
    }
  }

  getAssestById(assestId) {
    // this.servicesService.getAssestById(assestId).subscribe((res: any) => {
    //   this.files.map(x => (x.id == res.id ? res : x));
    //   let itemIndex = this.files.findIndex(item => item.id == res.id);
    //   this.files[itemIndex] = res;
    //   this._changeRef.detectChanges();
    // });
  }

  getSantizedResourceUrl(url: string) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

  deleteAssest(assestId) {
    this.dialogRef = this._matDialog.open(ConfirmationDialogComponent, {
      panelClass: "dlg-contct-div",
      disableClose: true,
      height: "250px",
      width: "450px",
      data: {
        dialogTitle: "Delete Asset!",
        content: "Are you sure you want to continue?"
      }
    });
    this.subs.add(
      this.dialogRef.afterClosed().subscribe((response: boolean) => {
        if (!response) {
          return;
        }
        this.subs.add(
          this.servicesService.deleteAsset(assestId, "").subscribe(
            (res: any) => {
              this.toastr.success("Successfully deleted asset");
              if (res) {
                this.files = this.files.filter(x => x.id != assestId);
                this._changeRef.detectChanges();
              }
            },
            (error: any) => {
              this.toastr.error(error.error.message);
            }
          )
        );
      })
    );
  }

  getLessionType(file) {
    let type = "other";
    if (this.isItemVideo(file.fileName)) {
      return "video";
    } else if (this.isFileTypeOther(file.fileName)) {
      return "other";
    } else if (this.isFileTypePDF(file.fileName)) {
      return "pdf";
    } else if (this.isItemAudio(file.fileName)) {
      return "audio";
    } else if (this.isFileTypeImage(file.fileName)) {
      return "image";
    }
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }
}
