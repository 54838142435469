export class QuizModel {
  guId: string;
  name: string;
  passingPercent: number;
  noOfQuestions: number;
  timeAlloted: number;
  isGenerateCertificate: boolean;
  imageUrl: string;
  scoreType: string;
  negativeMarkingPattern: string;
  instructions: string;
  isNoQuestions: boolean;
  course: string;
  chapter: string;
  section: string;
  courseName: string;
  chapterName: string;
  sectionName: string;
  qbChapterDistribution: any;
  lastUpdated: any;

  constructor(courseTest: any) {
    this.name = courseTest.name;
    this.guId = courseTest._id;
    this.instructions = courseTest.instructions;
    this.isNoQuestions = courseTest.isNoQuestions;
    this.passingPercent = courseTest.passingPercent;
    this.timeAlloted = courseTest.timeAlloted;
    this.lastUpdated = courseTest.updatedAt;
    this.noOfQuestions = courseTest.noOfQuestions;
    this.negativeMarkingPattern = courseTest.negativeMarkingPattern;
    this.isGenerateCertificate = courseTest.isGenerateCertificate;
    this.course = courseTest.course;
    this.chapter = courseTest.chapter;
    this.section = courseTest.section;
    this.courseName = courseTest.courseName;
    this.chapterName = courseTest.chapterName;
    this.sectionName = courseTest.sectionName;
    this.qbChapterDistribution = courseTest.qbChapterDistribution;
    this.scoreType = this.getScoreType(courseTest);
  }
  public getScoreType(quizObj) {
    if (quizObj.scoreType == "negativeMarking") {
      return "Negative Marking " + quizObj.negativeMarkingPattern;
    } else if (quizObj.scoreType == "grade") {
      return "Grade Marking ";
    } else {
      return "Linear Marking";
    }
  }
}
