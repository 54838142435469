import { environment } from "environments/environment";
import { Injectable, Output } from "@angular/core";
import * as AWS from "aws-sdk/global";
import * as S3 from "aws-sdk/clients/s3";
import { BehaviorSubject } from "rxjs";
import { AppLoadService } from "app/core/services/appload.service";
import { HttpClient, HttpEventType, HttpHeaders, HttpRequest } from "@angular/common/http";

@Injectable({
  providedIn: 'root'
})
export class FileUploadService {

  folder: string = "";
  uploadPercent: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  uploadData: any;
  baseUrl = environment.apiBaseUrl;
  constructor(
    private _apploadService: AppLoadService,
    private http: HttpClient
  ) {
    this.folder = `${environment.awsS3Folder}${_apploadService.getOrgId()}/`;
  }

  getUploadUrl(file, moduleName, folderName, fileType="", isAddIntro=false) {
    const contentType = file.type;
    const filename = file.name;
    let url = "";
    // if(isAddIntro) {
    //   url = `${this.baseUrl}/edcourse/assets/url?fileName=${filename}&fileType=${fileType}`;
    // } else {
    //   url = `${this.baseUrl}/edcourse/assets/url?fileName=${filename}&folderName=${folderName}`;
    // }

    url = `${this.baseUrl}/edcourse/assets/url`;
    let payload = {
      fileName: filename,
      folderName,
      fileType
    }

    // return this.http.get(url);
    return this.http.put(url, payload);
  }

  uploadFile(signedUrl, file, contentType) {
    const headers = new HttpHeaders({ "Content-Type": contentType });
    return  this.http.put(signedUrl, file, {
      headers: headers,
      reportProgress: true, //This is required for track upload process
      observe: "events",
    });
  }

  postAssest(assest: any) {
    let apiUrl = `${this.baseUrl}/edcourse/assets/`;
    return this.http.post(apiUrl, assest);
  }

  getAssest(guId: any) {
    let apiUrl = `${this.baseUrl}/edcourse/assets/${guId}`;
    return this.http.get(apiUrl);
  }
}
