export class AttendeeModel {
  guId: string;
  email: string;
  phone: string;
  name: string;
  courseBatch: object;
  progress: string;
  membership: any;

  constructor(classObj: any) {
    this.guId = classObj.guId;
    this.name = classObj.name;
    this.email = classObj.email;
    this.phone = classObj.phone;
    this.courseBatch = classObj.courseBatch;
    this.progress = classObj.progress;
    this.membership = classObj.membership;
  }
}
