import { HttpClient, HttpEventType, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { Upload } from 'tus-js-client';
import { MatSnackBar, MatSnackBarRef } from '@angular/material/snack-bar';
import { ProgressSnackbarComponent } from '../components/progress-snackbar/progress-snackbar.component';
import { AppSettingsService } from 'app/main/admin/app-settings/services/app-settings.service';
import { error } from 'console';
import { switchMap } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root',
})
export class BunnyUploadService {
  //Private Members
  private liveFileStatusArr = new BehaviorSubject<any[]>([]);
  private uploadQueue = new BehaviorSubject<any[]>([]);
  private isLoadingSubject = new BehaviorSubject<any>(null);
  private hideSnackbar = new BehaviorSubject<boolean>(false);
  private snackBarRef: MatSnackBarRef<ProgressSnackbarComponent> | null = null;
  private generateUploadId(fileName) {
    //generate upload id on the basis of timestamp with order secondsminuteshoursdayandyear with prefix upload_filename
    const timestamp = new Date().getTime();
    return `upload_${fileName}_${timestamp}`;
  }

  constructor(
    private _toastr: ToastrService,
    private httpClient: HttpClient,
    private _matSnackbar: MatSnackBar,
    private _settingService: AppSettingsService,
  ) {}

  // -----------------------------------------------------------------------------------------------------
  // Private Members
  // -----------------------------------------------------------------------------------------------------

  private createFileUrl(payload) {
    const url = `${environment.apiBaseUrl}/edmedia/file/init`;
    return this.httpClient.post(url, payload);
  }

  private createFileAsset(payload: any) {
    const url = `${environment.apiBaseUrl}/edmedia/file/${payload.fileId}`;
    return this.httpClient.post(url, {});
  }

  private createVideoObject(title) {
    const url = `${environment.apiBaseUrl}/edmedia/video/init?title=${title}`;
    return this.httpClient.get(url);
  }

  private createVideoAsset(payload: any, result: BunnyVideoObject) {
    const url = `${environment.apiBaseUrl}/edmedia/video`;
    const finalPayload = {
      ...payload,
      ...{
        videoId: result.videoId,
        libraryId: result.libraryId,
      },
    };
    return this.httpClient.post(url, finalPayload);
  }

  private openSnackbar() {
    this.snackBarRef = this._matSnackbar.openFromComponent(ProgressSnackbarComponent, {
      data: this.liveFileStatusArr,
      horizontalPosition: 'end',
      verticalPosition: 'bottom',
      panelClass: 'progress-snackbar',
    });
    1;

    this.snackBarRef.afterDismissed().subscribe(() => {
      this.snackBarRef = null;
    });
  }

  // -----------------------------------------------------------------------------------------------------
  // Public Members
  // -----------------------------------------------------------------------------------------------------

  public clear(all = false) {
    if (all) {
      this.liveFileStatusArr.next([]);
      this.uploadQueue.next([]);
      this.snackBarRef?.dismiss();
    } else {
      this.liveFileStatusArr.next([]);
      this.snackBarRef?.dismiss();
    }
  }

  public deleteUpload(uuid) {
    this.liveFileStatusArr.next(this.liveFileStatusArr.value.filter(f => f.uuid !== uuid));
    this.uploadQueue.next(this.uploadQueue.value.filter(f => f.uuid !== uuid));
  }

  public hide(value: boolean = true) {
    this.hideSnackbar.next(value);
    this.snackBarRef?.dismiss();
  }

  public updateFileStatus(fileStatus: any) {
    let currentStatus = this.liveFileStatusArr.value;
    const index = currentStatus.findIndex(f => f.uuid === fileStatus.uuid);
    if (index !== -1) {
      currentStatus[index] = fileStatus;
    } else {
      currentStatus.push(fileStatus);
    }
    this.liveFileStatusArr.next(currentStatus);
  }

  public uploadLink(payload, callback) {
    var fileStatus = { filename: 'Link', progress: 0, hash: '', uuid: this.generateUploadId('link'), upload: null, status: 'uploading' };
    this.updateFileStatus(fileStatus);
    this.httpClient.post(`${environment.apiBaseUrl}/edmedia/link`, payload).subscribe(
      (res: any) => {
        this.updateFileStatus({ ...fileStatus, progress: 100, status: 'completed' });
        callback.emit('refresh');
      },
      error => {
        this.updateFileStatus({ ...fileStatus, progress: -1, status: 'failed' });
      },
    );
    if (!this.snackBarRef && !this.hideSnackbar.value) {
      this.openSnackbar();
    }
  }

  // CLOUDINARY IMAGE UPLOAD
  public uploadImage(file: File, imageCallback) {
    const filename = file.name;
    var fileStatus = { filename, progress: 0, hash: '', uuid: this.generateUploadId(filename), upload: null, status: 'uploading' };
    this.updateFileStatus(fileStatus);

    const baseImageUploadUrl = 'https://api.cloudinary.com/v1_1/' + this._settingService.accountDetail.cloudinaryCloudName + '/upload';
    const formData: FormData = new FormData();
    formData.append('upload_preset', this._settingService.accountDetail.cloudinaryPreset);
    formData.append("folder", this._settingService.accountDetail.orgId +'-'+ this._settingService.accountDetail.tenantId);
    formData.append('file', file);
    const req = new HttpRequest('POST', `${baseImageUploadUrl}`, formData, {
      reportProgress: true,
      responseType: 'json',
    });
    this.httpClient.request(req).subscribe(
      event => {
        if (event.type === HttpEventType.UploadProgress) {
          const progress = Math.round((100 * event.loaded) / event.total);
          this.updateFileStatus({ ...fileStatus, progress: progress });
        } else if (event.type === HttpEventType.Response) {
          const response: any = event.body;
          const fileObject = {
            id: response.public_id,
            url: response.url,
          };
          imageCallback.emit({ type: 'image', value: response.public_id });
          this.updateFileStatus({ ...fileStatus, progress: 100, status: 'completed' });
        }
      },
      error => {
        console.error('Upload Error:', error);
        this.updateFileStatus({ ...fileStatus, progress: -1, status: 'failed' });
      },
    );

    if (!this.snackBarRef && !this.hideSnackbar.value) {
      this.openSnackbar();
    }
  }
  // CLOUDINARY IMAGE UPLOAD

  //EXLUSIVELY BUNNY CDN
  public uploadVideo(file: File, payload: any, callback) {
    const fileName = file.name;
    var fileStatus = { filename: fileName, progress: 0, hash: '', uuid: this.generateUploadId(fileName), upload: null, status: 'uploading' };
    this.updateFileStatus(fileStatus);

    this.createVideoObject(fileName).subscribe(
      (result: BunnyVideoObject) => {
        fileStatus.hash = result.sha256;
        const upload = new Upload(file, {
          endpoint: result.tusEndPoint,
          retryDelays: [0, 3000, 5000, 10000, 20000, 60000, 60000],
          headers: {
            AuthorizationSignature: result.sha256,
            AuthorizationExpire: String(result.expirationTime),
            VideoId: result.videoId,
            LibraryId: String(result.libraryId),
          },
          metadata: {},
          onError: error => {
            this.updateFileStatus({ ...fileStatus, progress: -1, upload, status: 'failed' });
          },
          onProgress: (bytesUploaded, bytesTotal) => {
            const progress = (bytesUploaded / bytesTotal) * 100;
            this.updateFileStatus({ ...fileStatus, progress, upload });
          },
          onSuccess: () => {
            this.createVideoAsset(payload, result).subscribe((result: any) => {
              callback.emit('refresh');
            });

            setTimeout(() => {
              this.updateFileStatus({ ...fileStatus, progress: 100, upload, status: 'completed' });
            }, 1000);
          },
        });

        upload.findPreviousUploads().then(function (previousUploads) {
          if (previousUploads?.length) {
            upload.resumeFromPreviousUpload(previousUploads[0]);
          }

          upload.start();
        });
      },
      error => {},
    );

    if (!this.snackBarRef && !this.hideSnackbar.value) {
      this.openSnackbar();
    }
  }
  //EXLUSIVELY BUNNY CDN END

  //FILE UPLOAD
  public uploadFile(file: File, payload: any, callback) {
    const fileName = file.name;
    var fileStatus = { filename: fileName, progress: 0, hash: '', uuid: this.generateUploadId(fileName), upload: null, status: 'uploading' };
    this.updateFileStatus(fileStatus);

    this.createFileUrl(payload).subscribe({
      next: (result: any) => {
        const req = new HttpRequest('PUT', result.response.signedUrl, file, {
          reportProgress: true,
          responseType: 'text', // Assuming text response type for AWS S3 PUT
        });
        this.updateFileStatus({ ...fileStatus, progress: 0 });
        this.httpClient.request(req).subscribe(
          event => {
            if (event.type === HttpEventType.UploadProgress) {
              const progress = Math.round((100 * event.loaded) / event.total);
              this.updateFileStatus({ ...fileStatus, progress: progress });
            } else if (event.type === HttpEventType.Response) {
              this.createFileAsset(result.response).subscribe(result => {
                callback.emit('refresh');
              });
              setTimeout(() => {
                this.updateFileStatus({ ...fileStatus, progress: 100, status: 'completed' });
              }, 1000);
            }
          },
          error => {
            this.updateFileStatus({ ...fileStatus, progress: -1, status: 'failed' });
          },
        );
      },
      error: error => {
        console.error('Error obtaining signed URL:', error);
        this.updateFileStatus({ ...fileStatus, progress: -1, status: 'failed' });
      },
    });
    if (!this.snackBarRef && !this.hideSnackbar.value) {
      this.openSnackbar();
    }
  }
  //FILE UPLOAD END

  public get uploadProgress() {
    return this.liveFileStatusArr.asObservable();
  }

  public get isLoading$() {
    return this.isLoadingSubject.asObservable();
  }

  public set isLoading(type: any) {
    const previousValue = this.isLoadingSubject.value;
    this.isLoadingSubject.next({ ...previousValue, ...type });
  }

  public get isUploading() {
    const incompletUpload = this.liveFileStatusArr.value.find(upload => upload.status == 'uploading') || null;
    if (incompletUpload) {
      return true;
    }
    return false;
  }
}

export interface BunnyVideoObject {
  videoId: string;
  libraryId: number;
  title: string;
  status: string;
  sha256: string;
  expirationTime: number;
  tusEndPoint: string;
  help: string;
}
