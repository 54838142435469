import { AttendeeModel } from "./attendee.model";
export class AttendeeSummary {
  totalRecords: string;
  attendees: AttendeeModel[];

  constructor(totalRecords: any, attendees: any) {
    this.totalRecords = totalRecords;
    this.attendees = attendees;
  }
}
