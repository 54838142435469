import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild, ViewEncapsulation, AfterViewInit } from '@angular/core';
import videojs from 'video.js';

@Component({
  selector: 'app-hls-player',
  templateUrl: './hls-player.component.html',
  styleUrls: ['./hls-player.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class HlsPlayerComponent implements OnInit, OnDestroy, AfterViewInit {

  @ViewChild('target', {static: true}) target: ElementRef;
  // see options: https://github.com/videojs/video.js/blob/maintutorial-options.html
  @Input() options: {
      fluid: boolean,
      aspectRatio: string,
      autoplay: boolean,
      sources: {
          src: string,
          type: string,
      }[],
  };
  @Input() height: string = "339";
  player: videojs.Player;

  constructor(
    private elementRef: ElementRef,
  ) { }

  ngOnInit() {
    
  }

  ngAfterViewInit() {
    // instantiate Video.js
    this.player = videojs(this.target.nativeElement , this.options, function onPlayerReady() {
    });
  }

  ngOnDestroy() {
    // destroy player
    if (this.player) {
      this.player.dispose();
    }
  }

}
