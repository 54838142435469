<div id="forms" class="page-layout carded fullwidth" fxLayout="column">
  <!-- HEADER -->
  <div class="new-header">
    <div style="float: left; display: flex;  align-items: center; justify-content: center;">
      <mat-icon class="logo-icon s-32 " style="margin-right: 10px;">
        share
      </mat-icon>
      <span class="logo-text h1">
        Third Party Settings
      </span>
    </div>
    <div style="float: right;">
      <button mat-icon-button [routerLink]="['/settings']" matTooltip="Back " aria-label="Back">
        <mat-icon>arrow_back</mat-icon>
      </button>
    </div>
  </div>
  <!-- <div class="header accent p-24 h-100" fxLayout="column" fxLayoutAlign="center center" fxLayout.gt-xs="row"
    fxLayoutAlign.gt-xs="space-between center">
    <div fxLayout="column" fxLayoutAlign="center center" fxLayout.gt-xs="column" fxLayoutAlign.gt-xs="center start">
      <div fxLayout="row" fxLayoutAlign="start center">
        <mat-icon class="secondary-text h1 mr-12">settings</mat-icon>
        <span class="secondary-text logo-text h2">Integrations</span>
      </div>

    </div>
    <a mat-raised-button class="reference-button mt-16 mt-sm-0" [routerLink]="['/settings']">
      <mat-icon class="mr-8">keyboard_backspace</mat-icon>
    </a>
  </div> -->
  <!-- / HEADER -->
  <!-- CONTENT -->
  <div
    class="content p-24"
    fxLayout="row wrap"
    fxLayoutAlign="center none"
    fxFlex="grow"
    fxLayout.gt-xs="row"
    fxFlex.gt-xs="100"
    fxFlex.gt-sm="100"
    style="padding-top: 0px !important;"
  >
    <div class="mb-24" style="width: 100%;">
      <div fxFlex="5"></div>
      <div class="card p-24 mr-24" fxLayout="column" fxLayoutAlign="center" fxFlex="grow" fxFlex="90" fxFlexFill>
        <h4 class="settingTitle">Payments</h4>
        <mat-divider style="display: inline-block;" fxFlex="100"></mat-divider>
        <div
          class="integrations"
          fxLayout="row wrap"
          fxLayoutAlign="space-around start"
          fxFlex="100"
          style="width: 100%;"
        >
          <div
            class="integration card-preview"
            fxFlex="100"
            fxFlex.gt-xs="100"
            fxFlex.gt-sm="100"
            [@animate]="{ value: '*', params: { y: '100%' } }"
          >
            <div>
              <h4 class="titleBtn" style="text-align: left; font-size: 17px; margin: 0px;"                 (click)="navigateToOauth('stripe')"
              >
                Stripe
                <mat-icon *ngIf="isStripeIntegrated" style="color: green; font-size: 17px; margin-right: -6px;"
                  >check_circle_outline</mat-icon
                >
              </h4>
              <div style="font-size: 14px; text-align: left; margin-top: 5px; color: #8b8e95;">
                Start Accepting Payments with Stripe in USD and other currencies, cardfees from 2.9% plus 30¢ per transaction
              </div>
            </div>
          </div>

          <div
            class="integration card-preview"
            fxFlex="100"
            fxFlex.gt-xs="100"
            fxFlex.gt-sm="100"
            [@animate]="{ value: '*', params: { y: '100%' } }"
          >
            <div>
              <h4
                (click)="provider('1')"
                class="titleBtn"
                style="text-align: left; font-size: 17px; margin: 0px;margin-top: 10px"
              >
                Nelnet
                <mat-icon *ngIf="isPspringIntegrated" style="color: green; font-size: 17px; margin-right: -6px;"
                  >check_circle_outline</mat-icon
                >
              </h4>
              <div style="font-size: 14px; text-align: left; margin-top: 5px; color: #8b8e95;">
                Start Accepting Payments in USD only with NelnetInc, Merchant fees from ACH Fees 0.80%, Credit Card 2.9% plus 30¢.
              </div>
            </div>
          </div>

          <div
            class="integration card-preview"
            fxFlex="100"
            fxFlex.gt-xs="100"
            fxFlex.gt-sm="100"
            [@animate]="{ value: '*', params: { y: '100%' } }"
          >
            <div>
              <h4
                (click)="provider('3')"
                class="titleBtn"
                style="text-align: left; font-size: 17px; margin: 0px;margin-top: 10px"
              >
                Razorpay
                <mat-icon *ngIf="isRazorpayIntegrated" style="color: green; font-size: 17px; margin-right: -6px;"
                  >check_circle_outline</mat-icon
                >
              </h4>
              <div style="font-size: 14px; text-align: left; margin-top: 5px; color: #8b8e95;">
                Accept payment in INR, starting from 2% per transaction
              </div>
            </div>
          </div>
          <div
            class="integration card-preview"
            fxFlex="100"
            fxFlex.gt-xs="100"
            fxFlex.gt-sm="100"
            [@animate]="{ value: '*', params: { y: '100%' } }"
          >
            <div>
              <h4
                (click)="provider('4')"
                class="titleBtn"
                style="text-align: left; font-size: 17px; margin: 0px;margin-top: 10px"
              >
                PhonePe
                <mat-icon *ngIf="isPhonepeIntegrated" style="color: green; font-size: 17px; margin-right: -6px;"
                  >check_circle_outline</mat-icon
                >
              </h4>
              <div style="font-size: 14px; text-align: left; margin-top: 5px; color: #8b8e95;">
                Accept payment using PhonePe
              </div>
            </div>
          </div>

          <h4 class="settingTitle" style="width: 100%; margin-top: 25px;">SMS Marketing</h4>
          <mat-divider style="display: inline-block;" fxFlex="100"></mat-divider>
          <div
            class="integration card-preview"
            fxFlex="100"
            fxFlex.gt-xs="100"
            fxFlex.gt-sm="100"
            [@animate]="{ value: '*', params: { y: '100%' } }"
          >
            <div>
              <h4
                (click)="navigateTo('cloud')"
                class="titleBtn"
                style="text-align: left; font-size: 17px; margin: 0px;margin-top: 10px;"
              >
                Twilio
                <mat-icon *ngIf="isTwilioIntegrated" style="color: green; font-size: 17px; margin-right: -6px;"
                  >check_circle_outline</mat-icon
                >
              </h4>
              <div style="font-size: 14px; text-align: left; margin-top: 5px; color: #8b8e95;">
                Enables to you send and recieve SMS messages and notifications right your phone. Get started by
                registering a phone number on Twilio
              </div>
            </div>
          </div>

          <div
            class="integration card-preview"
            fxFlex="100"
            fxFlex.gt-xs="100"
            fxFlex.gt-sm="100"
            [@animate]="{ value: '*', params: { y: '100%' } }"
          >
            <div>
              <h4
                (click)="navigateTo('plivo')"
                class="titleBtn"
                style="text-align: left; font-size: 17px; margin: 0px;margin-top: 10px;"
              >
                Plivo
                <mat-icon *ngIf="isPlivoIntegrated" style="color: green; font-size: 17px; margin-right: -6px;"
                  >check_circle_outline</mat-icon
                >
              </h4>
              <div style="font-size: 14px; text-align: left; margin-top: 5px; color: #8b8e95;">
                Enables to you send and recieve SMS messages and notifications right your phone. Get started by
                registering a phone number on Plivo
              </div>
            </div>
          </div>

          <h4 class="settingTitle" style="width: 100%; margin-top: 25px;">Campaign</h4>
          <mat-divider style="display: inline-block;" fxFlex="100"></mat-divider>

          <div
            class="integration card-preview"
            fxFlex="100"
            fxFlex.gt-xs="100"
            fxFlex.gt-sm="100"
            [@animate]="{ value: '*', params: { y: '100%' } }"
          >
            <div>
              <h4
                (click)="navigateTo('mailchimp')"
                class="titleBtn"
                style="text-align: left; font-size: 17px; margin: 0px;margin-top: 10px;"
              >
                Mailchimp
                <mat-icon *ngIf="isMailchimpIntegrated" style="color: green; font-size: 17px; margin-right: -6px;"
                  >check_circle_outline</mat-icon
                >
              </h4>
              <div style="font-size: 14px; text-align: left; margin-top: 5px; color: #8b8e95;">
                The integration enables two-way-sync between the Settings from Manage Audience Dropdown and Select
                Audience Name and Defaults and copy the listId highlighted in red
              </div>
            </div>
          </div>

          <div
            class="integration card-preview"
            fxFlex="100"
            fxFlex.gt-xs="100"
            fxFlex.gt-sm="100"
            [@animate]="{ value: '*', params: { y: '100%' } }"
          >
            <div>
              <h4
                (click)="navigateTo('brevo')"
                class="titleBtn"
                style="text-align: left; font-size: 17px; margin: 0px;margin-top: 10px;"
              >
                Brevo
                <mat-icon *ngIf="isBrevoIntegrated" style="color: green; font-size: 17px; margin-right: -6px;"
                  >check_circle_outline</mat-icon
                >
              </h4>
              <div style="font-size: 14px; text-align: left; margin-top: 5px; color: #8b8e95;">
                Brevo integration enabled
              </div>
            </div>
          </div>

          <h4 class="settingTitle" style="width: 100%; margin-top: 25px;">Communication</h4>
          <mat-divider style="display: inline-block;" fxFlex="100"></mat-divider>

          <div
            class="integration card-preview"
            fxFlex="100"
            fxFlex.gt-xs="100"
            fxFlex.gt-sm="100"
            [@animate]="{ value: '*', params: { y: '100%' } }"
          >
            <div>
              <h4
                (click)="navigateToOauth('omeeting')"
                class="titleBtn"
                style=" text-align: left; font-size: 17px; margin: 0px;margin-top: 10px;"
              >
                Online Meeting
                <mat-icon *ngIf="isOnlineMeetingIntegrated" style="color: green; font-size: 17px; margin-right: -6px;"
                  >check_circle_outline</mat-icon
                >
              </h4>
              <div style="font-size: 14px; text-align: left; margin-top: 5px; color: #8b8e95;">
                Online meeting enables you to conduct online training. Members can attend meetings after registration.
              </div>
            </div>
          </div>

         <h4 class="settingTitle" style="width: 100%; margin-top: 25px;">Email</h4>
         <mat-divider style="display: inline-block;" fxFlex="100"></mat-divider>

         <div
           class="integration card-preview"
           fxFlex="100"
           fxFlex.gt-xs="100"
           fxFlex.gt-sm="100"
           [@animate]="{ value: '*', params: { y: '100%' } }"
         >
           <div>
             <h4
               (click)="navigateTo('emails')"
               class="titleBtn"
               style=" text-align: left; font-size: 17px; margin: 0px;margin-top: 10px;"
             >
               Connect to email provider
               <mat-icon *ngIf="isOnlineMeetingIntegrated " style="color: green; font-size: 17px; margin-right: -6px;"
                 >check_circle_outline</mat-icon
               >
             </h4>
             <div style="font-size: 14px; text-align: left; margin-top: 5px; color: #8b8e95;">
               Connect to email provider for enabling customized email sending options.
             </div>
           </div>
         </div>
        </div>
      </div>
    </div>
  </div>
  <!-- <div
    class="content p-24"
    fxLayout="row wrap"
    fxLayoutAlign="center none"
    fxFlex="grow"
    fxLayout.gt-xs="row"
    fxFlex.gt-xs="50"
    fxFlex.gt-sm="33"
  >
    <div class="mb-24">
      <div fxFlex="5"></div>

      <div class="card p-24 mr-24" fxLayout="column" fxLayoutAlign="center" fxFlex="grow" fxFlex="90" fxFlexFill>
        <div class="h1 mb-16">Integrations</div>
        <mat-divider class="mb-24" style="display: inline-block;" fxFlex="100"></mat-divider>
        <div fxLayout="column" fxLayoutAlign="start">
          <div
            class="integrations"
            fxLayout="row wrap"
            fxLayoutAlign="space-around start"
            fxFlex="100"
            style="width: 100%;"
          >
            <div
              class="integration card-preview"
              *ngFor="let integration of integrationLists"
              fxFlex="100"
              fxFlex.gt-xs="50"
              fxFlex.gt-sm="33"
              [@animate]="{ value: '*', params: { y: '100%' } }"
            >
              <div class="fuse-card" [ngClass]="integration.color" (click)="navigateTo(integration.url)">
                <div class="integration-title">
                  <div class="h1">{{ integration.title }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div> -->
  <!-- / CONTENT -->
</div>
