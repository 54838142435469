import { HttpEventType } from "@angular/common/http";
import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { AccountDetail } from "app/main/admin/app-settings/models/account-details";
import { ToastrService } from "ngx-toastr";
import { SubSink } from "subsink";
import { UploadService } from "../../services/upload.service";
import { FileObject } from "../file-upload/file-upload.component";

@Component({
  selector: "app-video-upload",
  templateUrl: "./video-upload.component.html",
  styleUrls: ["./video-upload.component.scss"],
})
export class VideoUploadComponent implements OnInit, OnDestroy {
  @Input() videos: FileObject[] = [];
  @Input() title: string = "Add Files";
  @Input() description: string = "";
  @Input() buttonName: string = "Upload Files";
  @Input() folderName: string = "";
  @Input() moduleName: string = "";
  @Input() assestData: any = {};
  @Input() showPreview: boolean = true;
  @Input() isUploadDisabled: boolean = false;
  @Output() getFileObject = new EventEmitter<FileObject[]>();
  @ViewChild("videoPlayer") videoplayer: ElementRef;
  selectedFiles: FileList;
  uploadPercentage: number = 0;
  _tenantConfig: AccountDetail;
  subs = new SubSink();
  isLoading: boolean = false;

  constructor(
    private uploadService: UploadService,
    public toastr: ToastrService
  ) {
    this.subs.add(
      this.uploadService.uploadPercent.subscribe((num) => {
        this.uploadPercentage = num;
      })
    );
  }

  ngOnInit() {}

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  toggleVideo(event: any) {
    this.videoplayer.nativeElement.play();
  }

  isFileTypeMultimedia(fileType: string) {
    return (
      fileType.toLocaleLowerCase().includes("video") ||
      fileType.toLocaleLowerCase().includes("audio")
    );
  }

  isFileTypeImage(fileType: string) {
    return fileType.toLocaleLowerCase().includes("image");
  }

  isFileTypePDF(fileType: string) {
    return fileType.toLocaleLowerCase().includes("pdf");
  }

  isFileTypeOther(fileType: string) {
    return !(
      fileType.toLocaleLowerCase().includes("video") ||
      fileType.toLocaleLowerCase().includes("audio") ||
      fileType.toLocaleLowerCase().includes("pdf") ||
      fileType.toLocaleLowerCase().includes("image")
    );
  }

  selectFile(event) {
    this.selectedFiles = event.target.files;
    const file = this.selectedFiles.item(0);
    this.isLoading = true;
    this.subs.add(
      //  Get S3 Signed URL
      this.uploadService
        .getUploadUrl(file, this.moduleName, this.folderName)
        .subscribe((result: any) => {
          if (result) {
            //  Upload file to signed URL
            this.subs.add(
              this.uploadService
                .uploadFile(result.signedurl, file, file.type)
                .subscribe(
                  (aws: any) => {
                    if (aws.type == HttpEventType.UploadProgress) {
                      this.uploadPercentage = (aws.total * 100) / aws.loaded;
                    }
                    if (aws.type == HttpEventType.Response) {
                      this.uploadPercentage = 0;
                      const payload = {
                        ...this.assestData,
                        assetType: "file",
                        s3signedUrl: result.signedurl,
                      };
                      //  POST assest
                      this.subs.add(
                        this.uploadService.postAssest(payload).subscribe(
                          (assest: any) => {
                            // Get Assest
                            this.subs.add(
                              this.uploadService
                                .getAssest(assest._id)
                                .subscribe(
                                  (data: any) => {
                                    this.videos = [
                                      {
                                        id: data._id,
                                        url: data.s3url,
                                        fileType: file.type,
                                      },
                                    ];
                                    this.getFileObject.emit(this.videos);
                                    this.isLoading = false;
                                  },
                                  (error: any) => {
                                    this.isLoading = false;
                                    this.toastr.error(error.error.message);
                                  }
                                )
                            );
                          },
                          (error: any) => {
                            this.isLoading = false;
                            this.toastr.error(error.error.message);
                          }
                        )
                      );
                    }
                  },
                  (error: any) => {
                    this.isLoading = false;
                    this.toastr.error(error.error.message);
                  }
                )
            );
          }
        })
    );
  }
}
