<mat-card>
  <mat-card-header fxLayoutAlign="space-between center">
    <mat-card-title class="" *ngIf="loadSection == 'information'"> Save time in Wajooba and your inbox</mat-card-title>
    <mat-card-title class="title dialog-title" *ngIf="loadSection !== 'information' && loadSection !== 'emailIdentified' && loadSection !== 'emailIdentifiedAndConfirmed'">
      Set up your email account
    </mat-card-title>
    <mat-card-title class="title dialog-title" *ngIf="loadSection == 'emailIdentified'"> Set up your email account</mat-card-title>
    <mat-card-title class="title dialog-title" *ngIf="loadSection == 'emailIdentifiedAndConfirmed'"
      >Connect your {{ identifiedEmailProviderDetails.providerLabel }} account to Wajooba CRM</mat-card-title
    >
    <button mat-dialog-close mat-icon-button aria-label="Close dialog">
      <mat-icon>close</mat-icon>
    </button>
  </mat-card-header>
  <mat-card-content>
    <div class="content-align">
      <div fxLayout="row" fxLayoutAlign="space-around center" *ngIf="loadSection == 'information'">
        <div>
          <p><mat-icon style="color: #00c6d8; font-size: 17px">check_circle_outline</mat-icon> Send and schedule emails from Wajooba</p>
          <p><mat-icon style="color: #00c6d8; font-size: 17px">check_circle_outline</mat-icon> Log email replies to Wajooba automatically</p>
          <p><mat-icon style="color: #00c6d8; font-size: 17px">check_circle_outline</mat-icon> Suggest follow-up tasks and capture contact details from your email</p>
          <br />
          <p>Requires inbox automation</p>

          <button mat-raised-button type="button" color="accent" (click)="loadSection = 'submitEmail'">Connect your inbox</button>
        </div>
        <div fxHide.lt-sm>
          <img class="logo-icon" src="assets/images/logos/connect-email-desc.png" style="width: 152px; height: 152px" />
        </div>
      </div>
      <div class="ml-5" [hidden]="loadSection != 'submitEmail'">
        <form class="content-align" fxLayout="column" fxLayoutAlign="center start" [formGroup]="emailForm" style="align-items: center" fxLayoutGap="10px">
          <mat-form-field appearance="outline" fxFlex="100" style="width: 75%" class="m-0 p-0">
            <mat-label>Email Address</mat-label>
            <input formControlName="email" matInput (ngModelChange)="identifiedEmailProviderDetails = undefined" required />
            <mat-icon matSuffix class="secondary-text" style="color: #00c6d8">account_circle</mat-icon>
            <mat-error>Email is required!</mat-error>
          </mat-form-field>
          <div fxLayout="column" fxLayoutAlign="space-between start" *ngIf="identifiedEmailProviderDetails == undefined">
            <div class="mb-16">
              <mat-checkbox class="label-font-size" formControlName="isShareAccount">
                <span>Share your email account across</span>
              </mat-checkbox>
            </div>
            <button class="mat-accent m-0" mat-raised-button tabindex="1" [disabled]="!emailForm.valid" (click)="submitEmail()">
              Next
              <mat-icon *ngIf="isLoading">
                <mat-spinner diameter="20"></mat-spinner>
              </mat-icon>
            </button>
          </div>
          <div fxLayout="column" fxLayoutAlign="space-between start" *ngIf="identifiedEmailProviderDetails != undefined && identifiedEmailProviderDetails.providerName == 'other'">
            <p style="color: red; padding: 5px 0">We can't identify your email provider.</p>
            <button class="mat-accent m-0" mat-raised-button tabindex="1" aria-label="Add">Choose email provider</button>
          </div>
        </form>
      </div>
      <div class="ml-5" *ngIf="loadSection == 'emailIdentified'" fxLayout="column" fxLayoutAlign="space-between start">
        <mat-form-field appearance="outline" fxFlex="100" style="width: 75%; margin-bottom: -3rem">
          <mat-label>Email Address</mat-label>
          <input [(ngModel)]="usedEmail" matInput readonly />
          <mat-icon matSuffix class="secondary-text" style="color: #00c6d8">account_circle</mat-icon>
          <mat-error>Email is required!</mat-error>
        </mat-form-field>
        <p style="padding: 5px 0">Your email is hosted by {{ identifiedEmailProviderDetails.providerLabel }}. We recommend connecting to {{ identifiedEmailProviderDetails.appLabel }}</p>
        <button class="mat-accent m-0" mat-raised-button tabindex="1" aria-label="Add" [disabled]="!emailForm.valid" (click)="handleConnectToMailBtnClick()">
          Connect to {{ identifiedEmailProviderDetails.appLabel }}
        </button>
      </div>

      <div class="ml-5" *ngIf="loadSection == 'emailIdentifiedAndConfirmed'">
        <div fxLayout="column" fxLayoutAlign="space-around center" *ngIf="identifiedEmailProviderDetails.providerName == 'googlemail'">
          <div fxLayout="row">
            <img
              alt=""
              class="private-image private-image--object-fit-contain private-illustration"
              src="//static.hsappstatic.net/ui-images/static-2.671/optimized/google-gmail.svg"
              style="height: 40px"
            />
            <p style="margin-left: 5px">Gmail:&nbsp;</p>
            <p>{{ usedEmail }}</p>
          </div>

          <a class="link" style="font-size: 14px" (click)="loadSection = 'submitEmail'">Connect a different account</a>
          <p>Here's what to expect when you connect to Wajooba:</p>
          <textarea style="width: 100%; height: 100px" [readonly]="true" value>
When you integrate your Google account with the Wajooba platform, you can connect a number of Google services to your Wajooba account. By doing so, Wajooba will be able to access data and information from your Google account. Those services and types of data are outlined below;

                        Gmail: You can send and receive Gmail messages, schedule email sends, and modify email drafts in the Wajooba app. You will also be able to log and track emails sent in-app. Wajooba will have access to your Gmail data, like email headers, subject lines, and email bodies.
                        We'll only use your Google data to power different parts of our product that connect with the Google Workspace tools you use every day. It's your data. We just want to help you grow better.

                        You can remove the integration in the Wajooba app by navigating to Settings > Integrations > Email Integrations and clicking 'Disable'. You can also revoke Wajooba's access from within your Google account by following the instructions listed here.

                        For more information on the Google integration and the types of data we can access, please see our Privacy Policy.
                    </textarea
          >
          <div style="width: 100%" fxLayoutAlign="end center" class="mt-8">
            <button class="mat-accent m-0" mat-raised-button tabindex="1" aria-label="Add" (click)="confirmConnectEmail()">Continue</button>
          </div>
        </div>
        <div *ngIf="identifiedEmailProviderDetails.providerName == 'outlookmail'" fxLayout="column" fxLayoutAlign="space-around center">
          <div fxLayout="row">
            <img alt="" class="private-image private-image--object-fit-contain private-illustration" src="//static.hsappstatic.net/ui-images/static-2.672/optimized/outlook.svg" style="height: 40px" />
            <p style="margin-left: 5px">Microsoft Outlook:&nbsp;</p>
            <p>{{ usedEmail }}</p>
          </div>
          <a class="link" style="font-size: 14px">Connect a different account</a>
          <p>Here's what to expect when you connect to Wajooba:</p>
          <textarea style="width: 100%; height: 100px" [readonly]="true" value>
                        First, by granting Wajooba access to your inbox, your Wajooba will be able to locate relevant email conversations and log them in your CRM so you and your team can work on them together. Emails that you send from Wajooba will also appear in your Outlook sent folder, so there'll be no confusion about which emails live where.

                        This means that your Wajooba will have access to some information about your emails, like the email address you’re sending them from, the email addresses of your recipients, what’s in the subject line, and what’s in the body of the email. We’ll only use that access to power your W Outlook integration and make life easier for you, never for any nefarious purposes of our own. It's your data. We just want to help you use it better. You'll always be able to delete specific email records from your CRM with just a couple of clicks. Wajooba will also have access to view and create calendar events so we can ensure your CRM and calendar are perfectly in sync.

                        You'll be able to make more sweeping changes to how your integration works (for instance, removing the integration or adding another email account) just by adjusting your settings. It's all up to you. Want to learn more? Visit www.Wajooba.com/security for more information on our security policies and read the FAQ page about the Wajooba Outlook 365 integration.
                    </textarea
          >
          <div style="width: 100%" fxLayoutAlign="end center" class="mt-8">
            <button class="mat-accent m-0" mat-raised-button tabindex="1" aria-label="Add" (click)="confirmConnectEmail()">Continue</button>
          </div>
        </div>
        <div *ngIf="identifiedEmailProviderDetails.providerName == 'zohomail'" fxLayout="column" fxLayoutAlign="space-around center">
          <div fxLayout="row">
            <img
              alt=""
              class="private-image private-image--object-fit-contain private-illustration"
              src="//static.hsappstatic.net/ui-images/static-2.671/optimized/google-gmail.svg"
              style="height: 40px"
            />
            <p style="margin-left: 5px">Zoho:&nbsp;</p>
            <p>{{ usedEmail }}</p>
          </div>
          <a class="link" style="font-size: 14px">Connect a different account</a>
          <p>Here's what to expect when you connect to Wajooba:</p>
          <textarea style="width: 100%; height: 100px" [readonly]="true" value>                        ZOHO </textarea>
          <div style="width: 100%" fxLayoutAlign="end center" class="mt-8">
            <button class="mat-accent m-0" mat-raised-button tabindex="1" aria-label="Add" (click)="confirmConnectEmail()">Continue</button>
          </div>
        </div>
      </div>
    </div>
  </mat-card-content>
  <!--    <mat-divider style="margin-top:25px;margin-bottom:3px"></mat-divider>-->

  <!--    <mat-dialog-actions style="float:right;margin-right: 25px;margin-bottom:25px;" fxLayoutAlign="end end">-->
  <!--        &lt;!&ndash;      <button mat-raised-button mat-dialog-close tabindex="-1" style="margin-right: 10px;">Cancel</button>&ndash;&gt;-->
  <!--    </mat-dialog-actions>-->
</mat-card>
