import { Injectable } from "@angular/core";
import { environment } from "environments/environment";
import { HttpClient } from "@angular/common/http";
import { BehaviorSubject, Observable, of } from "rxjs";
import { map, tap, catchError, take } from "rxjs/operators";
import { Contact } from "./model/contact-model";
import { ContactSummary } from "./model/contact-list-summary";
import { AppConstants } from "app/core/settings/appconstants";
import * as moment from "moment";
import { ContactView } from "./model/contact-view-model";
import { AccountDetail } from "app/main/admin/app-settings/models/account-details";
import { AppLoadService } from "app/core/services/appload.service";
import { Tenant } from "app/core/models/tenant";

@Injectable({
  providedIn: "root"
})
export class ContactsService {
  contacts: any;
  contact: Observable<any>;
  contactGuId: any;

  contactSummary: ContactSummary = new ContactSummary();
  onContactsChanged: BehaviorSubject<any>;
  private _accountDetail: AccountDetail;
  searchFilterText: any = "";
  ctype: string = "contacts";
  onSelectedContactsChanged: BehaviorSubject<any>;
  selectedContacts$: Observable<any>;
  searchFilter: any;
  private _configSubjectContact: BehaviorSubject<any>;

  constructor(private http: HttpClient, private _appLoadService: AppLoadService) {
    this.onContactsChanged = new BehaviorSubject([]);
    this._configSubjectContact = new BehaviorSubject(this.contact);
    this.onSelectedContactsChanged = new BehaviorSubject([]);
    this.selectedContacts$ = this.onSelectedContactsChanged.asObservable();

    this.searchFilter = {
      ctagVal: [],
      btagVal: [],
      categoryVal: [],
      joinBy: "or",
      contact: "",
      search: "",
      toHeader: ""
    };
  }

  resetSearchFilter(): any {
    this.searchFilter = {
      joinBy: "and"
    };
  }

  setContactId(guId: any) {
    this.contactGuId = guId;
  }

  getContactId() {
    return this.contactGuId;
  }

  removeContactId() {
    this.contactGuId = "";
  }

  selectedContacts(contact): any {
    this.searchFilter.contact = contact;
    this.searchFilter.path = "";
    this.searchFilter.toHeader = "";
    this.onSelectedContactsChanged.next(this.searchFilter);
  }

  selectedContactsAndTag(contact, tag, tagName): any {
    this.searchFilter.contact = contact;
    this.searchFilter.tag = tag;
    this.searchFilter.tagName = tagName;
    this.searchFilter.path = "";
    this.searchFilter.toHeader = "";
    this.onSelectedContactsChanged.next(this.searchFilter);
  }

  selectedContactsHolder(contact, email, path, actiontype): any {
    this.searchFilter.contact = contact;
    this.searchFilter.toHeader = email;
    this.searchFilter.path = path;
    this.searchFilter.actiontype = actiontype;
    this.onSelectedContactsChanged.next(this.searchFilter);
  }
  selectedContactsHolderParam(contact, email, path, actiontype, classId, clsStartTime, scheduleId): any {
    this.searchFilter.contact = contact;
    this.searchFilter.toHeader = email;
    this.searchFilter.path = path;
    this.searchFilter.actiontype = actiontype;
    this.searchFilter.classId = classId;
    this.searchFilter.clsStartTime = clsStartTime;
    this.searchFilter.scheduleId = scheduleId;
    this.onSelectedContactsChanged.next(this.searchFilter);
  }

  deleteContact(contact): Observable<any> {
    let url = `${environment.apiBaseUrl}/snode/contact/${contact.guId}`;
    return this.http.delete(url);
  }

  public deleteContacts(contacts): Observable<any> {
    let payload: any = {};
    payload.contacts = contacts;
    let url = `${environment.apiBaseUrl}/snode/contact/delete`;
    return this.http.post(url, payload);
  }
  setEmailData(param, type, search, tag): any {
    if (tag) {
      this.resetSearchFilter();
      this.searchFilter.ctagVal = ["eq:" + tag.guId];
      this.searchFilter.toHeader = "All " + this.ctype + " with " + "tag" + " " + tag.name;
    }
    this.searchFilter.search = search;
    this.onSelectedContactsChanged.next(this.searchFilter);
  }

  getContactCounts() {
    const url = `${environment.apiBaseUrl}/snode/contact/stats`;
    return this.http.get(url);
  }
  
  getContactProfileImage (payload,Id) : Observable<any> {
    return this.http.get(`${environment.apiBaseUrl}/snode/contact/${Id}`, payload);
  }

  getContacts(param, type, search, tag, viewtype: any, stageName: any): Observable<any> {
    this.setEmailData(param, type, search, tag);

    let start = 0;
    let pageSize = 50;
    if (param != null) {
      start = param.pageIndex; //param.pageIndex * param.pageSize;
      pageSize = param.pageSize;
    }
    let tagId = "";
    if (tag) {
      tagId = "eq%3A" + tag.guId;
    }
    //OR condition will return if any condition is true, and should only be used for advanced conditions
    let joinByCondition = "and"; //other option is or

    let url = `${environment.apiBaseUrl}/snode/contact?start=${start}&max=${pageSize}&search=${search}&ctype=${type}&ctag=${tagId}&join_by=and`;
    if (viewtype == "sales" || viewtype == "account") {
      url += "&viewType=" + viewtype;
      if (stageName) {
        url += "&stageName=" + stageName;
      }
    }

    return this.http.get(url).pipe(
      map((result: any) => {
        this.contactSummary.recordTotal = result.recordsTotal;
        this.contactSummary.contactList = this.buildContacts(result.data);
        this.contacts = this.contactSummary.contactList;
        this.onContactsChanged.next(this.contactSummary);
        return this.contactSummary;
      })
    );
  }

  getMembershipPurches(full) {
    let MAX_LENGTH = 50;
    let membershipName = "-";
    // let membershipName = full.lastActiveMembershipName;
    // if (membershipName.includes(":")) {
    //   let strArray = membershipName.split(":");
    //   membershipName = strArray[1].trim().startsWith(strArray[0].trim())
    //     ? strArray[1].trim().substring(strArray[0].trim().length + 1, strArray[1].trim().length)
    //     : strArray[1].trim();
    //   membershipName = strArray[0] + " - " + membershipName;
    // }

    // let expiresOn = full.lastActiveMembershipExpiresOn;
    // let purchasedHtml = "";
    // if (full.lastActiveMembershipJustPurchased) {
    //   purchasedHtml =
    //     "" +
    //     '<span class="text-blue"  data-uib-tooltip="Recent purchase" style="display: inline;font-weight: 900;"><i class="fa fa-star"></i> </span>';
    // }
    // if (membershipName == "-") {
    //   return membershipName;
    // } else if (membershipName == "UNPAID") return membershipName;
    // else {
    //   //var extended = membershipName.length > MAX_LENGTH ? "..." : "";
    //   // var purchaseStr =   membershipName.substring(0, MAX_LENGTH) + extended

    //   /*if (expiresOn)
    //     purchaseStr += '<br><span style="font-size: 13px;" class="text-muted"><short> Expires -' + moment.unix(expiresOn).format('DD MMM YYYY') + '</short></span></br>'
    //   else
    //     purchaseStr += '<br><span class="text-muted"><short> Never Expires </short></span></br>'
    //   purchaseStr += '</span>'*/
    //   return membershipName;
    // }
  }
  buildContacts(data): Contact[] {
    let contacts: Array<Contact> = new Array();
    data.forEach(data => {
      let contact: Contact = new Contact();
      contact.name = data.name;
      contact.email = data.email;
      contact.phone = data.phone;
      contact.mailchimpInfo = data.mailchimpInfo;
      let dateCreated = moment.unix(data.dateCreated).fromNow();
      let dateUpdated = moment.unix(data.dateUpdated).fromNow();
      contact.dateCreated = dateCreated.includes("hours") ? "Today" : dateCreated;
      contact.dateUpdated = dateUpdated.includes("hours") ? "Today" : dateUpdated;
      contact.guId = data.guId;
      contact.picture = data.picture;
      contact.claim = data.claim || "";
      contact.isParent = data.isParent;
      contact.isParent = data.isParent;
      contact.balance = data.balance;
      contact.leadsource = data.leadSource;
      contact.mcStatus = data.mcStatus;
      contact.isUnread = data.isUnread;
      contact.isLead = data.isLead;
      if (Math.sign(data.balance) === 1) {
        contact.balanceType = "+";
      } else if (Math.sign(data.balance) === -1) {
        contact.balanceType = "-";
      }
      if (data.balance != null && data.balance != 0) {
        contact.balance = data.balance.toFixed(2);
      }
      if (data.balance < 0) {
        contact.balance = Math.abs(data.balance).toFixed(2);
      }
      contact.lastActiveMembershipExpiresOn =
        data.lastActiveMembershipExpiresOn == null
          ? ""
          : moment.unix(data.lastActiveMembershipExpiresOn).format("DD MMM YYYY");
      contact.lastActiveMembershipJustPurchased = data.lastActiveMembershipJustPurchased;
      contact.lastActiveMembershipName = this.getMembershipPurches(data);
      contacts.push(contact);
    });
    return contacts;
  }

  // addClient(payload): Observable<any> {
  //   const url = `${environment.apiBaseUrl}/rest/contact`;
  //   return this.http.post(url, payload).pipe(
  //       map((result: any) => {
  //       return result;
  //       }),
  //       catchError((error, caught): any => {
  //         return of(error);
  //       })
  //   );
  // }

  getClient(guId) {
    const url = `${environment.apiBaseUrl}/snode/contact/${guId}`;
    return this.http.get(url);
  }

  addClient(payload) {
    const url = `${environment.apiBaseUrl}/snode/contact`;
    return this.http.post(url, payload);
  }

  editClient(cid, payload): any {
    let url = `${environment.apiBaseUrl}/snode/contact/${cid}`;
    return this.http.put(url, payload);
  }

  getGuardian(): any {
    let url = `${environment.apiBaseUrl}/rest/contact/guardian`;
    return this.http.get(url);
  }

  getContactsGuardian(cid): any {
    let url = `${environment.apiBaseUrl}/rest/contact/${cid}/guardian`;
    return this.http.get(url);
  }

  addGuardian(payload): any {
    var url = `${environment.apiBaseUrl}/rest/contact/guardian`;
    return this.http.post(url, payload);
  }

  getContactsChildren(cid): any {
    let url = `${environment.apiBaseUrl}/rest/contact/${cid}/children`;
    return this.http.get(url);
  }

  getProvidersEmail(){
    let url = `${environment.apiBaseUrl}/authmgr/oauth/providers/mail`;
    return this.http.get(url);
  }

  resetPassword(): any {
    var url = `${environment.apiBaseUrl}/rest/contact/reset`;
    return this.http.put(url, {});
  }

  setUserPassword(payload): any {
    var url = `${environment.apiBaseUrl}/rest/user/changePassword`;
    return this.http.post(url, payload);
  }

  deleteActivity(id): any {
    var url = `${environment.apiBaseUrl}/snode/contact/${id}`;
    return this.http.delete(url);
  }

  getContactActivity(id): any {
    var url = `${environment.apiBaseUrl}/rest/contact/${id}/activity`;
    return this.http.get(url);
  }

  getCustomFields(isShowAll): Observable<any> {
    let url = `${environment.apiBaseUrl}/rest/customField`;
    if (isShowAll) {
      url = url + `?include=all`;
    }
    return this.http.get(url);
  }

  getCustomContactsFields(cid): Observable<any> {
    let url = `${environment.apiBaseUrl}/rest/contact/${cid}/customField`;
    return this.http.get(url);
  }

  private convertContactToModel(obj: any): any {
    return new ContactView(obj);
  }

  setCourseObj(contact: any, isNotify: boolean) {
    this.contact = contact;
    if (isNotify) {
      this._configSubjectContact.next(this.contact);
    }
  }

  getContactDetail(id, isNotify): Observable<any> {
    let url = `${environment.apiBaseUrl}/snode/contact/${id}`;
    if (id == -1) {
      url = `${environment.apiBaseUrl}/snode/contact?userType=walkin`;
    }

    return this.http.get(url).pipe(
      map((result: any) => {
        this.contact = this.convertContactToModel(result);
        this.setCourseObj(this.contact, isNotify);
        return this.contact;
      })
    );
  }
  addActivity(cid, payload): any {
    var url = `${environment.apiBaseUrl}/rest/contact/${cid}/activity`;
    return this.http.post(url, payload);
  }

  deleteContactActivity(cid, guId): any {
    var url = `${environment.apiBaseUrl}/rest/contact/${cid}/activity/${guId}`;
    return this.http.delete(url);
  }

  updateActivity(cid, guId, payload): any {
    var url = `${environment.apiBaseUrl}/rest/contact/${cid}/activity/${guId}`;
    return this.http.put(url, payload);
  }

  updateContact(guId, payload): any {
    var url = `${environment.apiBaseUrl}/snode/contact/${guId}`;
    return this.http.put(url, payload);
  }

  updateContactTag(payload) {
    var url = `${environment.apiBaseUrl}/rest/contactTag`;
    return this.http.put(url, payload);
  }

  addTag(payload) {
    var url = `${environment.apiBaseUrl}/rest/tag`;
    return this.http.post(url, payload);
  }

  deleteTag(cid, payload) {
    var url = `${environment.apiBaseUrl}/snode/contact/${cid} `;
    return this.http.put(url, payload);
  }

  getTags(guId) {
    var url = `${environment.apiBaseUrl}/rest/contact/${guId}/tag`;
    return this.http.get(url).pipe(map((resp: any) => resp.tags));
  }

  getAllContactDetail(cid) {
    var url = `${environment.apiBaseUrl}/snode/contact/${cid}`;
    return this.http.get(url);
  }

  getAllTags(): Observable<any> {
    let apiUrl = `${environment.apiBaseUrl}/rest/tag?type=tag`;
    return this.http.get(apiUrl);
  }

  getContactNotes(cid): Observable<any> {
    let apiUrl = `${environment.apiBaseUrl}/rest/contact/${cid}/notes`;
    return this.http.get(apiUrl);
  }

  getContactChildren(guId: any) {
    let url = `${environment.apiBaseUrl}/rest/contact/${guId}/children`;
    return this.http.get(url).pipe(
      map((result: any) => {
        return result;
      })
    );
  }

  getImportDetails() {
    let apiUrl = `${environment.apiBaseUrl}/rest/contact/import`;
    return this.http.get(apiUrl);
  }

  public importContacts(payload,categoryId) {
    const url = `${environment.apiBaseUrl}/rest/contact/import?categoryId=${categoryId}`;
    return this.http.post(url, payload);
  }

  getcloudinaryBaseURL(scaling_modes: "fill" | "Fit" | "Limit" = "fill", height: number = 500, width: number = 750) {
    return this._appLoadService.onTenantConfigChanged.pipe(
      take(1),
      map((tenantConfig: any) => {
        return {
          cloudinaryBaseURL: `https://res.cloudinary.com/${tenantConfig.cloudName}/image/upload/c_${scaling_modes},h_${height},w_${width}/`,
          others: tenantConfig
        };
      })
    );
  }

  getSentEmails(data):Observable<any>{
    const payload = {
      email:data.email,
      tenantId:this._appLoadService.tenantConfig.tenantId,
      orgId:this._appLoadService.tenantConfig.orgId
    }
    let url = `${environment.apiBaseUrl}/esend/email/contact`;
    return this.http.post(url,payload,{params : {start:(data.pageIndex * data.pageLength),max:data.pageSize}})
  }

  getStudentProgress(guId): Observable<any> {
    let url = `${environment.apiBaseUrl}/rest/courseAttendee/${guId}/studentProgress`;
    return this.http.get(url);
  }

  getDownloadPdf(guId): Observable<any> {
    let url = `${environment.apiBaseUrl}/cform/pdf/cactivityreport/${guId}`;
    return this.http.get(url);
  }

  postEmailBody(payload): any {
    let url = `${environment.apiBaseUrl}/rest/message/sendCredentials`;
    return this.http.post(url, payload);
  }

  postFauthEmailBody(payload): any {
    let url = `${environment.apiBaseUrl}/fauth/fauth/resetpwd `;
    return this.http.post(url, payload);
  }
}
