<ngx-spinner bdColor="rgba(51, 51, 51, 0.8)" size="default" type="ball-spin-clockwise">
  <p style="color: white">Please Wait.</p>
</ngx-spinner>
<!-- startshere -->
<!-- <div class="page-layout carded fullwidth" fxLayout="column" id="forms"> -->
<div id="forms" class="page-layout carded fullwidth" fxLayout="column">
  <!-- HEADER -->

  <div class="new-header">
    <div style="float: left; display: flex;  align-items: center; justify-content: center;">
      <mat-icon class="logo-icon s-32 " style="margin-right: 10px;">
        view_module
      </mat-icon>
      <span class="logo-text h1"> PayTm </span>
    </div>
    <div style="float: right;">
      <button mat-icon-button (click)="backToPaymentComponent()" matTooltip="Back " aria-label="Back">
        <mat-icon>arrow_back</mat-icon>
      </button>
    </div>
  </div>

  <!-- / HEADER -->

  <!-- CONTENT -->
  <div
    class="content p-24"
    fxFlex="grow"
    fxFlex.gt-sm="33"
    fxFlex.gt-xs="50"
    fxLayout="row wrap"
    fxLayout.gt-xs="row"
    fxLayoutAlign="center none"
  >
    <div class="mb-24">
      <div fxFlex="5"></div>

      <form
        class="mat-card mat-elevation-z4 p-24 mr-24"
        style="border-radius: 4px;"
        fxFlex="90"
        fxFlex="grow"
        (ngSubmit)="onSubmit()"
        fxFlexFill
        fxLayout="column"
        fxLayoutAlign="center"
        [formGroup]="payTmForm"
        name="payTmForm"
      >
        <div fxLayout="column" fxLayoutAlign="start">
          <div
            class="integrations"
            fxFlex="100"
            fxLayout="row wrap"
            fxLayoutAlign="space-around start"
            style="width: 100%"
          >
            <div
              [@animate]="{ value: '*', params: { y: '100%' } }"
              class="card-preview"
              fxFlex.gt-md="30"
              fxFlex.gt-sm="50"
              fxFlex.gt-xs="40"
            >
              <div class="fuse-card paytm">
                <div class="mailchimp-title">
                  <div class="h1">Payment Provider - PayTm</div>
                </div>
              </div>
            </div>
            <div
              [@animate]="{ value: '*', params: { y: '100%' } }"
              class="card-preview"
              fxFlex.gt-md="70"
              fxFlex.gt-sm="50"
              fxFlex.gt-xs="40"
            >
              <div class="fuse-card grey">
                <p>Start Accepting Payments with PayTm</p>

                <p>Navigate to Account -> API Keys for following information</p>
              </div>
            </div>
          </div>
        </div>

        <mat-divider class="mb-24" fxFlex="100" style="display: inline-block"></mat-divider>
        <div fxFlex="1 0 auto" fxLayout="row wrap" fxLayoutAlign="center">
          <!-- <mat-checkbox fxFlex="100">Active Payment Provider</mat-checkbox> -->
          <mat-checkbox (change)="validateTestMode()" formControlName="isPaytmSandbox" fxFlex="100">
            <div class="h2">Test Mode</div>
          </mat-checkbox>
        </div>
        <br />

        <div fxFlex="1 0 auto" fxLayout="row wrap" fxLayoutAlign="center">
          <mat-form-field appearance="outline" fxFlex="100" matInput>
            <mat-label>Merchant Id*</mat-label>
            <input formControlName="paytmMerchantId" class="right-align" matInput />
            <mat-error></mat-error>
          </mat-form-field>
        </div>

        <div fxFlex="1 0 auto" fxLayout="row wrap" fxLayoutAlign="center">
          <mat-form-field appearance="outline" fxFlex="100" matInput>
            <mat-label>Merchant Key*</mat-label>
            <input formControlName="paytmMerchantKey" class="right-align" matInput />
            <mat-error></mat-error>
          </mat-form-field>
        </div>
        <!-- </div> -->
        <div fxFlex="1 0 auto" fxLayout="row wrap" fxLayoutAlign="end">
          <button (click)="backToPaymentComponent()" class="mr-4" color="accent" mat-stroked-button>Cancel</button>
          <button color="accent" mat-raised-button type="submit">
            Submit
          </button>
        </div>
      </form>
    </div>
  </div>
  <!-- / CONTENT -->
</div>
