import { environment } from "environments/environment";
import { Injectable, Output } from "@angular/core";
import * as AWS from "aws-sdk/global";
import * as S3 from "aws-sdk/clients/s3";
import { BehaviorSubject } from "rxjs";
import { AppLoadService } from "app/core/services/appload.service";
import { HttpClient, HttpEventType, HttpHeaders, HttpRequest } from "@angular/common/http";

@Injectable({
  providedIn: "root",
})
export class UploadService {
  folder: string = "";
  uploadPercent: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  uploadData: any;
  baseUrl = environment.apiBaseUrl;
  constructor(
    private _apploadService: AppLoadService,
    private http: HttpClient
  ) {
    this.folder = `${environment.awsS3Folder}${_apploadService.getOrgId()}/`;
  }

  getUploadUrl(file, moduleName, folderName) {
    const contentType = file.type;
    const filename = file.name;
    const url = `${this.baseUrl}/${moduleName}/assets/url?file-type=${contentType}&file-name=${filename}&folderName=${folderName}`;
    return this.http.get(url);
  }

  uploadFile(signedUrl, file, contentType) {
    const headers = new HttpHeaders({ "Content-Type": contentType });
    return  this.http.put(signedUrl, file, {
      headers: headers,
      reportProgress: true, //This is required for track upload process
      observe: "events",
    });
  }

  postAssest(assest: any) {
    let apiUrl = `${this.baseUrl}/edcourse/assets/`;
    return this.http.post(apiUrl, assest);
  }

  getAssest(guId: any) {
    let apiUrl = `${this.baseUrl}/edcourse/assets/${guId}`;
    return this.http.get(apiUrl);
  }
}
