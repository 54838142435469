<ngx-spinner bdColor="rgba(51, 51, 51, 0.8)" size="default" type="ball-spin-clockwise">
    <p style="color: white">Please Wait.</p>
</ngx-spinner>
<div id="forms" class="page-layout carded fullwidth" fxLayout="column" *ngIf="!isLoading">
    <div class="new-header">
        <div style="float: left; display: flex; align-items: center; justify-content: center">
            <mat-icon class="logo-icon s-32" style="margin-right: 10px"> share</mat-icon>
            <span class="logo-text h1"> Email Integration </span>
        </div>
        <div style="float: right">
            <button mat-icon-button [routerLink]="['/settings/integrations']" matTooltip="Back " aria-label="Back">
                <mat-icon>arrow_back</mat-icon>
            </button>
        </div>
    </div>

    <div fxLayout="row wrap" fxLayoutGap="24px grid" class="p-24">
        <div class="hero-content">
            <h1>Email</h1>
            <p style="margin-top: -5px;">Connect your personal email accounts to wajooba to log, track, send, and
                receive emails in the wajooba
                CRM. To manage any team emails.</p>
            <div>
                <button *ngIf="!isLoading" mat-raised-button type="button" color="accent"
                    (click)="openConnectEmailDialog()">
                    Connect Personal Email
                </button>
            </div>
        </div>
    </div>
    <div class="content" fxLayout="row wrap" class="p-24 mb-24 w-100p boxshadow-media">

        <mat-table style="width: 100%;" [dataSource]="dataSource" [@animateStagger]="{ value: '50' }">
            <!-- Email Column -->
            <ng-container matColumnDef="email">
                <mat-header-cell *matHeaderCellDef style="background: #EFEFEF;" width="55%">Email</mat-header-cell>
                <mat-cell *matCellDef="let item">
                    <span class="mobile-label">Email:&nbsp;&nbsp;</span>
                    <img *ngIf="item.productName == 'googlemail'" class="logo-icon"
                        src="assets/images/logos/google-gmail.svg">
                    <img *ngIf="item.productName == 'zohomail'" class="logo-icon"
                        src="assets/images/logos/zoho-mail.png">
                    <img *ngIf="item.productName == 'outlookmail'" class="logo-icon"
                        src="assets/images/logos/outlook-mail.svg">
                </mat-cell>
            </ng-container>

            <!-- User Name Column -->
            <ng-container matColumnDef="userName">
                <mat-header-cell *matHeaderCellDef style="background: #EFEFEF;">User name</mat-header-cell>
                <mat-cell *matCellDef="let item">
                    <span class="mobile-label">User name:&nbsp;&nbsp;</span>
                    <a>{{ item.userName }}</a>
                </mat-cell>
            </ng-container>

            <!-- Shared Account Column -->
            <ng-container matColumnDef="sharedAccount">
                <mat-header-cell *matHeaderCellDef style="background: #EFEFEF;">Shared account</mat-header-cell>
                <mat-cell *matCellDef="let item">
                    <span class="mobile-label">Shared account:&nbsp;&nbsp;</span>
                    <mat-checkbox class="label-font-size" [(ngModel)]="item.isShareAccount"
                        (click)="$event.preventDefault()" fxFlex="100">
                    </mat-checkbox>
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="Action">
                <mat-header-cell *matHeaderCellDef style="background: #EFEFEF;">Action</mat-header-cell>
                <mat-cell *matCellDef="let item">
                    <span class="mobile-label">Action:&nbsp;&nbsp;</span>
                    <p matTooltip="Delete">
                        <mat-icon (click)="deleteEmail(item._id)" style="color: #ff8845">
                            delete
                        </mat-icon>
                    </p>
                </mat-cell>
            </ng-container>

            <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>

            <!-- Data Row -->
            <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
            <tr class="mat-row" *matNoDataRow>
                <td class="mat-cell" colspan="4">
                    <h3 style="text-align: center">No data found!</h3>
                </td>
            </tr>
        </mat-table>
    </div>
</div>