import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../../../environments/environment';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class OauthIntegrationService {
  constructor(private _httpClient: HttpClient) {}

  getOauthProvidersAPI() {
    let url = `${environment.apiBaseUrl}/authmgr/oauth/providers`;
    return this._httpClient.get(url).pipe(
      map(data => {
        return data;
      }),
    );
  }

  getOauthOnlineMeetingProvidersAPI() {
    let url = `${environment.apiBaseUrl}/authmgr/oauth/providers/omeeting`;
    return this._httpClient.get(url).pipe(
      map(data => {
        return data;
      }),
    );
  }

  submitRegenerateUrl(id: string, payload: any) {
    return this._httpClient.put(`${environment.apiBaseUrl}/snode/classrule/${id}/regenerateurl`, payload);
  }

  connectOnlineMeetingAPI(providerName) {
    return this._httpClient.get(`${environment.apiBaseUrl}/authmgr/oauth/connect/${providerName}`);
  }

  revokeOauthAPI(id) {
    return this._httpClient.post(`${environment.apiBaseUrl}/authmgr/oauth/revoke/${id}`, {});
  }

  redirectGoogleLogin(tid: string, orgId: string): Observable<any> {
    const payload = {
      orgId: orgId,
      tid: tid
    };
    return this._httpClient.post(`${environment.apiBaseUrl}/authmgr/pauth/glogin`, payload);
  }
}

