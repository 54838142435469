import { LessonModel } from './lesson.model';

export class SectionModel {
    chapters: LessonModel[];
    course: string;
    dateCreated: string;
    guId: string;
    isActive: boolean;
    lastUpdated: string;
    sequence: number;
    title: string

    constructor(section: any) {
        this.chapters = section.chapters;
        this.course = section.course;
        this.dateCreated = section.dateCreated;
        this.guId = section.guId;
        this.isActive = section.isActive;
        this.lastUpdated = section.lastUpdated;
        this.sequence = section.sequence;
        this.title = section.title;
    }
}
