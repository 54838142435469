<div class="dialog-content-wrapper">
  <mat-toolbar class="mat-accent m-0 accent" matDialogTitle>
    <mat-toolbar-row
      fxLayout="row"
      fxLayoutAlign="space-between center"
      style="flex-direction: row;
    box-sizing: border-box;
    display: flex;
    place-content: center space-between;
    align-items: center;"
    >
      <span class="title dialog-title"></span>
      <button
        _ngcontent-serverapp-c343=""
        aria-label="Close dialog"
        class="mat-focus-indicator ng-tns-c343-43 mat-icon-button mat-button-base"
        mat-dialog-close=""
        mat-icon-button=""
        ng-reflect-aria-label="Close dialog"
        ng-reflect-dialog-result=""
        type="button"
      >
        <span class="mat-button-wrapper"
          ><mat-icon
            _ngcontent-serverapp-c343=""
            aria-hidden="true"
            class="mat-icon notranslate material-icons mat-icon-no-color"
            data-mat-icon-type="font"
            role="img"
            >close</mat-icon
          ></span
        ><span
          class="mat-ripple mat-button-ripple mat-button-ripple-round"
          matripple=""
          ng-reflect-centered="true"
          ng-reflect-disabled="false"
          ng-reflect-trigger="[object HTMLButtonElement]"
        ></span
        ><span class="mat-button-focus-overlay"></span>
      </button>
    </mat-toolbar-row>
  </mat-toolbar>
  <mat-dialog-content class="p-24 pb-0 m-0" style="margin-top:25px; margin-bottom:25px">
    <div
      class="page-layout simple"
      id="course-type-dlg"
      style="background: white;
        max-width:600px!important;align-items: center;  margin-top: 20%;"
    >
      <div class="content " style="min-height: 15rem; overflow: hidden">
        <div fxLayout="column" fxLayoutAlign="center" style="max-width:600px!important;">
          <h4
            style="text-align: left;color: #000000;margin: 0px;padding-left: 13px;
                    font-weight: 800; font-size: 2rem;"
          >
            You need to choose a user before purchasing
          </h4>
        </div>
      </div>
    </div>
  </mat-dialog-content>
  <mat-divider style="margin-top:25px;margin-bottom:3px"></mat-divider>

  <mat-dialog-actions style="float: right; margin-right: 25px; margin-bottom: 0px; justify-content: flex-end;">
    <button
      style="background-color: #00C6D8;"
      class="mat-focus-indicator mat-raised-button mat-button-base mat-primary"
      color="primary"
      mat-raised-button=""
    >
      <span class="mat-button-wrapper">OK</span><span class="mat-ripple mat-button-ripple" matripple=""></span
      ><span class="mat-button-focus-overlay"></span>
    </button>
  </mat-dialog-actions>
</div>
