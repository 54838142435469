import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {fuseAnimations} from '../../../../../@fuse/animations';

@Component({
  selector: 'app-no-user-select-dialog',
  templateUrl: './no-user-select-dialog.component.html',
  styleUrls: ['./no-user-select-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: fuseAnimations
})
export class NoUserSelectDialogComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
