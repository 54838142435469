import { CourseModel } from 'app/main/shared/models/course.model';
import { AppConstants } from 'app/core/settings/appconstants';

export class PricingModel {
  activePeriod: number;
  activePeriodType: string;
  billFrom: string;
  billingFreqText: string;
  billingFrequency: any;
  cardFees: string;
  category: CourseModel;
  categoryGuId: any;
  credits: number;
  currency: string;
  guId: string;
  index?: number;
  isChargeCreditCardFees: boolean;
  isDisableItem: boolean;
  isDiscountSubscriptionFees: boolean;
  isError: boolean;
  isErrorMsg: string;
  isIntroMembership: boolean;
  isNeverExpires: any;
  isOtherPrice: any;
  isOtherPriceStr: any;
  isPromotionItem: any;
  isTaxable: boolean;
  membershipPaymentType: any;
  membershipType: string;
  membershipTypeStr: string;
  name: string;
  noOfBillingCycles: number;
  price: number;
  registrationFees: number;
  rule: any;
  ruleId: any;
  session: any;
  sessions: number;
  itemType: string;
  expiryPeriod?: number;
  expiryPeriodType?: string;
  partnerNetwork?: object;
  singleLineDescription?: string;

  constructor(index: number, item: any) {
    this.name = item.name;
    this.guId = item.guId || null;
    this.membershipType = item.session == "unlimited" ? "unlimited" : item?.membershipType || 'card';
    this.cardFees = item.isChargeCreditCardFees ? 'Yes' : 'No';
    this.billingFrequency = item.subscriptionInfo != null ? item.subscriptionInfo.billingFrequency : 'monthly';
    this.billFrom = item.isBillFromNextMonth ? 'Next Month' : 'Immediately';
    //this.session = item.credits || "";
    this.price = item.price || 0;
    this.isDisableItem = item.isDisableItem;
    this.isPromotionItem = item.isPromotionItem != null ? item.isPromotionItem : true;
    this.membershipPaymentType = item.membershipType == 'recurring' ? 'recurring' : 'onetime';
    this.isIntroMembership = item.isIntroMembership || false;
    this.categoryGuId = item.category == null ? item.categoryGuId : item.category.guId;
    this.isOtherPriceStr = item.isOtherPrice ? 'true' : 'false';
    this.isOtherPrice = item.isOtherPrice || false;
    this.isChargeCreditCardFees = item.isChargeCreditCardFees || false;
    this.credits = item.membershipInfo != null && item.membershipInfo.credits != null ? item.membershipInfo.credits : 0;
    this.session = this.credits > 0 ? 'multiple' : 'unlimited';
    this.activePeriodType = item.membershipInfo != null && item.membershipInfo.activePeriodType != null ? item.membershipInfo.activePeriodType : 'years';
    this.activePeriod = item.activePeriod || 1;
    this.registrationFees = item.registrationFees || 0;
    this.noOfBillingCycles = item.subscriptionInfo == null ? 0 : item.subscriptionInfo.numberOfBillingCycles;
    this.isNeverExpires = item.subscriptionInfo != null ? '' + item.subscriptionInfo.isNeverExpires : 'true';
    this.isDiscountSubscriptionFees = item.isDiscountSubscriptionFees || false;
    this.billingFreqText = item.subscriptionInfo == null ? '' : item.subscriptionInfo.billingFreqText;
    this.membershipTypeStr = item.membershipType;
    this.index = index;
    this.category = item.category;
    this.rule = item.rule || null;
    this.isTaxable = item.isTaxable || false;
    this.currency = item.currency || AppConstants.DEFAULT_CURRENCY;
    this.itemType = item.itemType || 'membership';
    this.expiryPeriod = 3;
    this.expiryPeriodType = 'years';
    this.partnerNetwork = {};
    this.singleLineDescription = "";
  }
}
