import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
  ViewChild,
  ViewEncapsulation
} from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { PageEvent } from "@angular/material/paginator";
import { fuseAnimations } from "@fuse/animations";
import { FuseConfirmDialogComponent } from "@fuse/components/confirm-dialog/confirm-dialog.component";
import { TenantPing } from "app/core/models/tenantPing";
import { AppLoadService } from "app/core/services/appload.service";
import { AppUtilsService } from "app/core/services/apputils.service";
import { AppConstants } from "app/core/settings/appconstants";
import { SubSink } from "subsink";
import { ProductService } from "app/main/admin/products/services/product.service";
import { ToastrService } from "ngx-toastr";

@Component({
  selector: "app-template-list",
  templateUrl: "./app-template-list.component.html",
  styleUrls: ["./app-template-list.component.scss"],
  animations: fuseAnimations,
  encapsulation: ViewEncapsulation.None
})
export class AppTemplateListComponent implements OnInit, OnChanges, OnDestroy {
  subs = new SubSink();
  @Input() type = 'course'
  @Input() unTemplates: any[];
  @Input() untotal: number;
  @Input() skipTemplate: boolean;
  @Output() onTemplateClick = new EventEmitter<any>();
  @ViewChild("filter") filter: ElementRef;
  templateList: any[] = [];
  start: number = 0;
  length = 100;
  pageSize = 50;
  pageSizeOptions: number[] = [5, 10, 25,50, 100];
  // MatPaginator Output
  pageEvent: PageEvent;
  _tenantConfig: any;
  _imagePrefixPath: any;
  orgId: any;
  constructor(
    public _matDialog: MatDialog,
    private cdRef: ChangeDetectorRef,
    private _appLoadService: AppLoadService,
    private _productsService: ProductService,
    public toastr: ToastrService
  ) {}

  ngOnInit(): void {
    this.orgId = this._appLoadService.getOrgId();
    // this.length = this.total;
    //console.log("this.unTemplatesTotal", this.total)
  }
  ngOnChanges(changes: SimpleChanges) {
    // setTimeout(() => {
    this.pageSize = 50;
    this.start = 0;
    this.length = changes.untotal.currentValue;
    //this.pageSize = this.length
    //this.setPrefixPath();

    this.filterTemplates();
    this.getTemplatesPagination(null);
    this.getTemplates(null);
    this.cdRef.detectChanges();
    //}, 100);
  }

  getTemplates(event: PageEvent | null) {
    if (event != null) {
      this.start = event.pageIndex * event.pageSize;
      this.pageSize = event.pageSize;
    }

    if (this.unTemplates) {
      this.templateList = this.unTemplates.slice(this.start, this.start + this.pageSize);
    }
  }

  getTemplatesPagination(event: PageEvent | null) {
    if (event != null) {
      this.start = event.pageIndex * event.pageSize;
      this.pageSize = event.pageSize;
    }

    this.subs.add(
      this._productsService
        .getUntemplatesByOrgId(this._appLoadService.getOrgId(), event?.pageIndex ? event?.pageIndex : 0, this.pageSize, this.type)
        .subscribe((result: any) => {
          if (result.data.length > 0) {
            this.templateList = [];
            this.length = result.total;
            this.templateList = result.data;
          }
        })
    );
  }

  setPrefixPath() {
    this.subs.add(
      this._appLoadService.onTenantConfigChanged.subscribe(tenantConfig => {
        this._tenantConfig = tenantConfig;
        this._imagePrefixPath = AppUtilsService.getCloudinaryPath(
          this._tenantConfig.cloudName,
          AppConstants.IMG_OPTIONS_TEMPLATE
        );
      })
    );
  }

  getThumbnailImg(templateObj) {
    if (templateObj.s3Url) {
      return templateObj.s3Url;
    } else {
      return "assets//images//default-image.jpg";
    }
  }

  deleteTemplate(templateObj) {
    let confirmDialogRef = this._matDialog.open(FuseConfirmDialogComponent, {
      disableClose: false,
      width: "490px",
      id: "dialog"
    });
    confirmDialogRef.componentInstance.confirmMessage = `${templateObj.name} will be deleted, Are you sure?`;
    confirmDialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.subs.add(
          this._productsService.deleteUntemplate(templateObj._id).subscribe(
            (result: any) => {
              this.toastr.success("Template deleted successfully");
              //this.unTemplates.splice(this.unTemplates.findIndex(a => a._id === templateObj._id) , 1)
              this.start = 0;
              this.pageSize = 50;
              this.getTemplatesPagination(null);
            },
            errorResp => {
              this.toastr.error(errorResp.error.errors.message);
            }
          )
        );
      }
    });
  }

  skipConfirmPopup(): void {
    let confirmDialogRef = this._matDialog.open(FuseConfirmDialogComponent, {
      disableClose: false,
      width: "490px",
      id: "dialog"
    });
    confirmDialogRef.componentInstance.confirmMessage = `Its always a good Idea to add more information about your course, you can always add it later. Would you like to Proceed?`;
    confirmDialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.onTemplateClick.emit("skip");
      }
    });
  }

  filterTemplates() {
    this.templateList = this.unTemplates; //this.unTemplates?.filter(x => x.name.toLowerCase().includes(`${this.filter.nativeElement.value.toLowerCase()}`));
    this.length = this.untotal;
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }
}
