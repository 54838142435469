import { CourseModel } from "./course.model";
export class CourseSummary {
  totalRecords: string;
  courseList: CourseModel[];

  constructor(totalRecords: any, courseList: any) {
    this.totalRecords = totalRecords;
    this.courseList = courseList;
  }
}
