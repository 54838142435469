import {ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {fuseAnimations} from '../../../../../../@fuse/animations';
import {TenantUserService} from '../../../../../core/services/tenantuser.service';
import {ToastrService} from 'ngx-toastr';
import {SubSink} from 'subsink';
import {AppSettingsService} from '../../services/app-settings.service';
import {MatDialog, MatDialogRef, } from "@angular/material/dialog";
import {ConnectEmailComponent} from "../connect-email/connect-email.component";
import { MatTableDataSource } from '@angular/material/table';
import { FuseConfirmDialogComponent } from '@fuse/components/confirm-dialog/confirm-dialog.component';
import { OauthIntegrationService } from 'app/main/admin/oauth/oauth-integration/oauth-integration.service';

@Component({
    selector: 'app-zoom-meeting',
    templateUrl: './connect-email-page.component.html',
    styleUrls: ['./connect-email-page.component.scss'],
    animations: fuseAnimations,
})
export class ConnectEmailPageComponent implements OnInit, OnDestroy {
    displayedColumns: string[] = ['email', 'userName', 'sharedAccount','Action'];
    dataSource: MatTableDataSource<any> = new MatTableDataSource();
    subs = new SubSink();
    isLoading:boolean = false;
    connectedEmail: any[] = [];
    private confirmDialogRef: MatDialogRef<FuseConfirmDialogComponent>;
    constructor(private _formBuilder: FormBuilder,
                private _tenantUserService: TenantUserService,
                private _settingsService: AppSettingsService,
                public toastr: ToastrService,
                private _matDialog: MatDialog,
                private _oauthIntegrationService: OauthIntegrationService,
                private _changeDetectorRef: ChangeDetectorRef) {
    }


    ngOnInit(): void {
        this.getTenantInfo();
    }
  openConnectEmailDialog(){
      let eventObj = {
        date: new Date()
      };
      const dialogRef = this._matDialog.open(ConnectEmailComponent, {
        panelClass: "connect-email-dialog",
        data: eventObj,
        disableClose: true,
        width: "780px"

      });
    }

    getTenantInfo() {
        const validEmailProviders = ['googlemail', 'zohomail', 'outlookmail'];
        this.subs.add(
          this._settingsService.getAccountDetail().subscribe((data) => {
            const filteredEmails = data.oauthProviderList.filter(item => 
              validEmailProviders.includes(item.productName)
            );
            this.dataSource.data = filteredEmails;
          })
        );
      }

      deleteEmail(emailId: string) {
        this.confirmDialogRef = this._matDialog.open(FuseConfirmDialogComponent, {
          disableClose: true,
        });
        this.confirmDialogRef.componentInstance.confirmMessage = 'Do you want to revoke selected account\'s access, Are you sure?';
        this.confirmDialogRef.afterClosed().subscribe(result => {
          if (result) {
            this.isLoading = true;
            this.confirmDialogRef = null;
            this.subs.add(
              this._oauthIntegrationService.revokeOauthAPI(emailId).subscribe(
                (resultData: any) => {
                  this.toastr.success('Account unlinked success.');
                  this.getTenantInfo();
                  this.isLoading = false;
                  this._changeDetectorRef.detectChanges();
                },
                error => {
                  this.toastr.error('Some error occurred. Please contact your account manager.');
                  this.isLoading = false;
                  // Also trigger change detection if there's an error
                  this._changeDetectorRef.detectChanges();
                }
              ),
            );
          }
        });
      }

    ngOnDestroy(): void {
        this.subs.unsubscribe();
    }
}
