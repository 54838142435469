<div class="page-layout carded fullwidth" fxLayout="column" fxLayoutAlign="start">
  <div fxLayout="row" fxLayoutAlign="center" fxFlex="1 0 auto">
    <div class="mt-5" style="font-size: 16px; font-weight: bold">
      {{ title }}
    </div>
  </div>
  <div fxLayout="row wrap" fxLayoutAlign="start" fxFlex="1 1 auto" *ngIf="description != ''">
    <span class="custom-control" fxFlex="100">{{ description }}</span>
  </div>
  <div fxLayout="row" fxLayoutAlign="center" fxFlex="1 0 auto" *ngIf="videos.length > 0 && showPreview">
    <span class="close" (click)="videos = []">&times;</span>
    <div class="col-sm-2 img-wrapper" fxFlex="20" *ngFor="let file of videos">
      <img [src]="file.url" alt="" class="p-1" *ngIf="isFileTypeImage(file.fileType)" />
      <video
        width="270"
        controls
        disabled="true"
        (click)="toggleVideo()"
        *ngIf="isFileTypeMultimedia(file.fileType)"
        style="max-height: 220px"
        #videoPlayer
      >
        <source [src]="file.url" [type]="file.fileType" />
      </video>
      <a target="_blank" [href]="file.url" download="HTML 5 PDF" fxFlex *ngIf="isFileTypePDF(file.fileType)">
        <img style="width: 100%; height: 70px" src="https://passets.wajooba.com/img/image_Inprogress.svg" />
      </a>
      <a target="_blank" [href]="file.url" download="HTML 5 PDF" fxFlex *ngIf="isFileTypeOther(file.fileType)">
        <img style="width: 100%; height: 70px" src="https://passets.wajooba.com/img/image_Inprogress.svg" />
      </a>
    </div>
  </div>
  <div fxLayout="row wrap" fxLayoutAlign="center" fxFlex="1 0 auto" style="padding-top: 5px">
    <input type="file" #fileInput (change)="selectFile($event)" hidden />
    <button
      mat-stroked-button
      color="accent"
      class="mt-5 mb-16"
      (click)="fileInput.click()"
      [disabled]="isUploadDisabled"
    >
      {{ buttonName }}
    </button>
    <mat-progress-spinner
      *ngIf="isLoading"
      [diameter]="19"
      [color]="'accent'"
      [mode]="'indeterminate'"
      style="display: inline-block !important; margin-left: 12px"
    >
    </mat-progress-spinner>
    <!-- <mat-form-field style="width: 100%;" appearance="outline" matInput>
            <mat-toolbar>
                <span matNativeControl>{{title}}</span>
                <input matInput hidden readonly />
            </mat-toolbar>
            <input type="file" ng2FileSelect [uploader]="uploader" />
        </mat-form-field>       -->
  </div>
</div>
