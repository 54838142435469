<div class="dialog-content-wrapper">
    <mat-toolbar matDialogTitle class="mat-accent m-0 accent">
        <mat-toolbar-row fxLayoutAlign="space-between center" style=" display: flex; justify-content: space-between; ">
            <span class="title dialog-title">Website Setting</span>
            <button mat-dialog-close mat-icon-button aria-label="Close dialog">
                <mat-icon>close</mat-icon>
            </button>
        </mat-toolbar-row>
    </mat-toolbar>
    <mat-dialog-content class="p-24 pb-0 m-0" style="margin-top:25px; margin-bottom:25px">
        <div style="width: 100%;">
            <form [formGroup]="seoForm" fxLayout="column" fxLayoutAlign="center" fxFlex="grow" style="width: 100%">
                <div fxLayout="column">
                    <div fxLayout="column">
                        <span class="label"> Title</span>
                        <span class="custom-control secondary-text" fxFlex="100">
                          A SEO title key must contain your target key.
                        </span>
                        <mat-form-field appearance="outline" fxFlex="100">
                            <mat-label>SEO Keywords</mat-label>
                            <input matInput formControlName="title" maxlength="255" />
                        </mat-form-field>
                    </div>
<!--                  <div fxLayout="column">-->
<!--                      <span class="label"> SEO Keyword</span>-->
<!--                        <span class="custom-control secondary-text" fxFlex="100">-->
<!--                          Keyword Desc goes here-->
<!--                        </span>-->
<!--                        <mat-form-field appearance="outline" fxFlex="100">-->
<!--                            <mat-label>SEO Keywords</mat-label>-->
<!--                            <input matInput formControlName="seoKeywords" maxlength="255" />-->
<!--                        </mat-form-field>-->
<!--                    </div>-->
                  <div fxLayout="column">
                      <span class="label"> SEO Description</span>
                        <span class="custom-control secondary-text" fxFlex="100">
                          SEO Desc goes here
                        </span>
                        <mat-form-field appearance="outline" fxFlex="100">
                            <mat-label>SEO Keywords</mat-label>
                            <input matInput formControlName="seoDescription" maxlength="255" />
                        </mat-form-field>
                    </div>
                </div>
            </form>
        </div>
    </mat-dialog-content>
<!--    <mat-divider style="margin-top:25px;margin-bottom:3px"></mat-divider>-->

    <mat-dialog-actions style="float:right;margin-left: 25px;margin-right: 25px;">
        <button mat-raised-button mat-dialog-close tabindex="-1" style="margin-right: 10px;">Cancel</button>
        <button
                class="mat-accent m-0"
                mat-raised-button
                tabindex="1"
                (click)="submitForm()"
                [disabled]="templateForm.invalid"
                aria-label="Add"
        >
            Submit

        </button>
    </mat-dialog-actions>
</div>
