import { ClassModel } from "./class.model";
export class ClassSummary {
  totalRecords: string;
  classList: ClassModel[];

  constructor(totalRecords: any, classList: any) {
    this.totalRecords = totalRecords;
    this.classList = classList;
  }
}
