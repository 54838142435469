export class CourseModel {
  guId: string;
  name: string;
  isShowOnWebsite: boolean;
  paymentType: string;
  programCount: number;
  dateUpdatedStr: string;
  dateCreatedStr: string;
  membershipCount: string;
  externalLink: string;
  welcomeEmail: any;
  welcomeTemplate: any;
  isFranchiseCourse: any;
  isOnlineCourse: boolean;
  shortDescription: any;
  teacher: any;
  host: any;
  hostName: any;
  currentBatch: any;
  courseSections: any;
  attendees: any;
  dateUpdated: any;
  isDisabled: boolean;
  isSendWelcomeEmail: boolean;
  image1: any;
  isAllowDemoChapters: any;
  url: string;

  constructor(course: any) {
    this.name = course.name;
    this.guId = course.guId;
    this.isShowOnWebsite = course.isShowOnWebsite;
    this.paymentType = course.paymentType;
    this.programCount = course.programCount;
    this.membershipCount = course.membershipCount;
    this.dateUpdatedStr = course.dateUpdatedStr;
    this.dateUpdated = course.dateUpdated;
    this.dateCreatedStr = course.dateCreatedStr;
    this.externalLink = course.externalLink;
    this.welcomeEmail = course.welcomeEmail;
    this.welcomeTemplate = course.welcomeTemplate;
    this.isFranchiseCourse = course.isFranchiseCourse;
    this.isOnlineCourse = course.isOnlineCourse;
    this.shortDescription = course.shortDescription;
    this.teacher = course.teacher;
    this.host = course.host;
    this.hostName = course.teacher ? course.teacher.fullName : course.host;
    this.currentBatch = course.currentBatch;
    this.courseSections = course.courseSections;
    this.attendees = course.attendees;
    this.isDisabled = course.isDisabled;
    this.isSendWelcomeEmail = course.isSendWelcomeEmail;
    this.image1 = course.image1;
    this.isAllowDemoChapters = course.isAllowDemoChapters;
    this.url = course.url;
  }
}
