import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { BunnyUploadService } from '../../services/bunny-upload.service';

import { SubSink } from 'subsink';
import { MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';
@Component({
  selector: 'app-progress-snackbar',
  templateUrl: './progress-snackbar.component.html',
  styleUrls: ['./progress-snackbar.component.scss'],
})
export class ProgressSnackbarComponent implements OnInit, OnDestroy {
  fileStatusArr: any[] = [];
  private _subs = new SubSink();
  constructor(@Inject(MAT_SNACK_BAR_DATA) public data: any[], private bunnyUploadService: BunnyUploadService) {}

  ngOnInit(): void {
    this._subs.add(
      this.bunnyUploadService.uploadProgress.subscribe(data => {
        this.fileStatusArr = data;
      }),
    );
  }

  public getStatus(status){
    switch(status){
      case 'completed': return "check_circle";
      case 'failed': return "error";
      default: return "pause_circle_filled"
    }
  }

  public action(file){
    // switch(file.progress){
    //   case 100: this.bunnyUploadService.clear(file.uuid);
    //   break;
    //   case -1: this.bunnyUploadService.clear(file.uuid);
    //   break;
    //   default: file?.upload?.abort()
    // }
  }

  public clear(){
    this.bunnyUploadService.clear();
  }

  public hide(){
    this.bunnyUploadService.hide();
  }

  ngOnDestroy(): void {
    this._subs.unsubscribe();
  }
}
