export class TenantPing {
  bankFees?: string;
  bigLogo?: string;
  buyButtonLabel?: string;
  // calendarVisibility?: string;
  cardFees?: string;
  taxPercent?: string;
  cloudName?: string;
  csymbol?: string;
  customDomain?: string;
  email?: string;
  phone?: string;
  environmentName?: string;
  isAddressNeededForEvent?: false;
  isNewTenant?: boolean;
  // isDonationPublicCheckout?: false;
  isRegAndPurchaseOnSamePage?: false;
  isShowCalenderView?: false;
  isShowCourses?: false;
  isAllowCreateCourses?: false;
  isShowDonation?: false;
  isShowScheduleMenu?: false;
  isShowStoreMenu?: false;
  isSupportGrn?: boolean;
  isShowRegistrationLink?: false;
  isShowRoomName?: false;
  isShowSchedule?: false;
  isWaiverFormToBeSigned?: false;
  isMasterFranchise?: false;
  isMultiCountrySupport?: false;
  logo?: string;
  name?: string;
  orgId?: string;
  masterOrgId?: string;
  orgGuId?: string;
  smallLogo?: string;
  timezone?: string;
  version?: string;
  waiverFormLink?: string;
  website?: string;
  webstate?: string;
  title?: string;
  seoDescription?: string;
  seoKeywords?: string;
  workshopLabel?: string;
  isAuthValid?: boolean;
  address?: any;
  itemExpiresAfter?: any;
  scheduleLabel?: any;
  promotionLabel?: any;
  isEnableCourses?: any;
  isEnableOnlineCourses?: any;
  tenantId?:any;
  [tenantAuthViewCmd: string]: any;
  homepage?: string;
  home?: string;
  page1?: string;
  page2?: string;
  page3?: string;
  page4?: string;
  page5?: string;
  forms?: any;
  registrationLinkName?: string;
  socials?: any;
  web?: any;
  org?: any;
}
