import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { fuseAnimations } from '@fuse/animations';
import { TenantUserService } from 'app/core/services/tenantuser.service';
import { SubSink } from 'subsink';
import { AppSettingsService } from '../../../services/app-settings.service';
import { AppConstants } from "app/core/settings/appconstants";
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
    selector: 'app-paytm',
    templateUrl: './paytm.component.html',
    styleUrls: ['./paytm.component.scss'],
    animations: fuseAnimations,
})
export class PaytmComponent implements OnInit {

    @Output() payment: EventEmitter<any> = new EventEmitter<any>();
    payTmForm: FormGroup;
    subs = new SubSink();
    adminList;
    isOrganizePlan;
    isExpandPlan;
    isLeapPlan;
    isFreePlan;
    isAccountOwner: boolean = false;
    isPaytm: boolean = true;
    isPaymentSpringIntegrated: boolean = false;
    isPaymentProviderIntegrated: boolean = false;
    isPaymentSpring: boolean = false;
    isPaytmIntegrated = false;
    isShowError;
    issubmitting: boolean = false;

    tenant: any;

    constructor(private _formBuilder: FormBuilder, private _tenantUserService: TenantUserService,
        private _settingsService: AppSettingsService, private _router: Router,
        private _activatedRoute: ActivatedRoute, public toastr: ToastrService,
        private SpinnerService: NgxSpinnerService) {
    }

    ngOnInit(): void {
        const self = this;
        this.SpinnerService.show();
        this.payTmForm = this._formBuilder.group({
            paytmMerchantId: ["", Validators.required],
            paytmMerchantKey: ["", Validators.required],
            isPaytmSandbox: [""]
        });
        this.getTenantDetail();
    }

    getTenantDetail() {
        this.subs.add(
          this._settingsService.getTenantDetail().subscribe(
            result => {
              let resultData: any = result;
              this.tenant = resultData.data;
              let providerObj = this.tenant.paymentKeys.find(item => {
                return item.provider == AppConstants.PAYMENT_TYPE.PAYTM_PAYMENT.toLowerCase();
              });
              if (providerObj != null && providerObj != undefined) {
                this.tenant.paytm = {
                    isPaytmSandbox : providerObj.isTestMode,
                    paytmMerchantId: providerObj.apiKey,
                    paytmMerchantKey: providerObj.secretKey
                }; 
                this.tenant.paymentProvider = providerObj.provider; 
                if (this.tenant.paytm.paytmMerchantKey) {
                  this.isPaytm = true;
                }
                this.isAccountOwner = true;
                this.bindFormData();
              }
              this.SpinnerService.hide();
             },
            error => {
              this.SpinnerService.hide();
              //this.toastr.error(error);
              this.getTenantInfoV4();
            }
          )
        );
      }
    
      getTenantInfoV4() {
        this.subs.add(
          this._settingsService.getAccountDetail().subscribe(data => {
            this.tenant = { ...data };
            this.setPaymentProvider();
            this.bindFormData();
            this.SpinnerService.hide();
           })
        );
      }

    backToPaymentComponent(): void {
        this.payment.emit(null);
    }

    validateTestMode() {
    }

    updatePayload(payload) {
        this.SpinnerService.show();
        this.subs.add(
            this._settingsService.updatePaytmProvider(payload).subscribe(
                result => {
                    this.tenant = result;
                    this.SpinnerService.hide();
                    this.toastr.success("Successfully updated Settings.");
                    this.payment.emit(null);
                },
                error => {
                    this.SpinnerService.hide();
                    this.toastr.error(error);
                }
            )
        );
    }

    setPaymentProvider() {
        this.tenant.plantype = this.tenant.plantype.toLowerCase();
        this.isOrganizePlan =
            this.tenant.plantype == AppConstants.InternalPlanNameMonthly.ORGANIZE_PLAN_MONTHLY ||
            this.tenant.plantype == AppConstants.InternalPlanNameYearly.ORGANIZE_PLAN_YEARLY;
        this.isExpandPlan =
            this.tenant.plantype == AppConstants.InternalPlanNameMonthly.EXPAND_PLAN_MONTHLY ||
            this.tenant.plantype == AppConstants.InternalPlanNameYearly.EXPAND_PLAN_YEARLY;
        this.isLeapPlan =
            this.tenant.plantype == AppConstants.InternalPlanNameMonthly.LEAP_PLAN_MONTHLY ||
            this.tenant.plantype == AppConstants.InternalPlanNameYearly.LEAP_PLAN_YEARLY;
        this.isFreePlan = this.tenant.plantype == AppConstants.Plans.FREE_PLAN || this.tenant.plantype == "";
        if (this.tenant?.paytmMerchantKey) {
            this.isPaytm = true;
        }
        this.isAccountOwner = true;
    }

    bindFormData(): void {
        // Reactive Form
        this.payTmForm = this._formBuilder.group({
            paytmMerchantId: [this.tenant?.paytm?.paytmMerchantId],
            paytmMerchantKey: [this.tenant?.paytm?.paytmMerchantKey],
            isPaytmSandbox: [this.tenant?.paytm?.isPaytmSandbox]
        });
    }

    onSubmit() {
        this.tenant.paymentProvider = AppConstants.PAYMENT_TYPE.PAYTM_PAYMENT.toLowerCase();
        let paytmInfo = this.payTmForm.value;
        this.tenant.paytm = {};
        this.tenant.paytm.paytmMerchantId = paytmInfo.paytmMerchantId;
        this.tenant.paytm.paytmMerchantKey = paytmInfo.paytmMerchantKey;
        this.tenant.paytm.isPaytmSandbox = paytmInfo.isPaytmSandbox;
        if (this.tenant.paytm.payTmMerchantKey == "") {
            if (this.tenant.paytm.payTmMerchantKey != "") {
                if (this.tenant.paytm.isTestMode == "") {
                    this.isShowError = "Test mode should be on";
                    return;
                }
            }
        }

        var payload: any = {};
        payload.paytm = {};
        var tenant = this.tenant;
        payload.paytmMerchantId = tenant.paytm?.paytmMerchantId;
        payload.paytmMerchantKey = tenant.paytm?.paytmMerchantKey;
        payload.isPaytmSandbox = tenant.paytm?.isPaytmSandbox; 
        this.isPaytm = true;
        this.updatePayload(payload);
    }

}
