<div class="page-layout carded fullwidth" fxLayout="column" fxLayoutAlign="start">
  <div fxLayout="row" fxLayoutAlign="start" fxFlex="1 0 auto" *ngIf="isMultipleUpload || !(isMainUpload && files.length > 0)">
    <div class="mt-5" style="font-size: 16px; font-weight: bold">
      {{ title }}
    </div>
  </div>
  <div fxLayout="row wrap" fxLayoutAlign="start" fxFlex="1 1 auto" *ngIf="isMultipleUpload || (description != '' && !(isMainUpload && files.length > 0))">
    <span class="custom-control" fxFlex="100">{{ description }}</span>
  </div>
  <div fxLayout="row wrap" fxLayoutAlign="start" fxFlex="1 0 auto" class="pt-5" *ngIf="isMultipleUpload || !(isMainUpload && files.length > 0)">
    <input type="file" #fileInput (change)="selectFile($event)" multiple="true" hidden />
    <button style="margin-top: 5px;background: #00C6D8;color: #FFFFFF" mat-stroked-button color="white" class="mb-16" (click)="fileInput.click()" [disabled]="isUploadDisabled || (!isMasterTenant && isFranchiseCourse)" type="button">
      {{ buttonName }}
    </button>

    <mat-progress-spinner *ngIf="isLoading" [diameter]="19" [color]="'accent'" [mode]="'indeterminate'" style="display: inline-block !important; margin-left: 12px"> </mat-progress-spinner>
  </div>
  <div fxLayout="row wrap" fxLayoutAlign="center" fxFlex="1 0 auto" style="padding-top: 5px">
    <mat-progress-bar *ngIf="isLoading" class="mb-12" [color]="'accent'" [mode]="'determinate'" [value]="uploadPercentage" [bufferValue]="100"> </mat-progress-bar>
  </div>
  <div *ngIf="isLoading" fxLayout="row wrap" fxLayoutAlign="start" fxFlex="1 0 auto" style="padding-top: 5px">
    <h4 style="margin: 0px; text-align: left" class="br-green">Please wait.., till the content is uploaded i.e progressbar is complete</h4>
  </div>

  <div *ngIf="!isNewlook" style="width: 100%">
    <div fxLayout="row" fxLayoutAlign="center" class="m-12 w-100p" *ngFor="let file of files" style="margin-left: 3px !important; padding-right: 5px !important">
      <div *ngIf="file.fileName" fxLayout="column" fxLayoutAlign="start" fxFlex="1 0 auto" class="w-100p mat-card">
        <div fxLayout="row" fxLayoutAlign="start" class="m-12" *ngIf="isStudentFile">
          <div fxLayout="column" fxLayoutAlign="start" fxFlex="70">
            <span class="title-text" fxFlex>{{ file.fileName }}</span>
          </div>
          <div fxLayout="column" fxLayoutAlign="start" fxFlex="30">
            <div fxLayout="row wrap" fxLayoutAlign="end">
              <span class="title-text" fxFlex fxLayoutAlign="end">{{ file.ownerName }}</span>
            </div>
            <div fxLayout="row wrap" fxLayoutAlign="end">
              <span class="title-text" fxFlex fxLayoutAlign="end">{{ file.updatedAt | date : "short" }}</span>
            </div>
          </div>
        </div>
        <div fxLayout="row" fxLayoutAlign="start" class="m-12" *ngIf="!isStudentFile">
          <span class="title-text" fxFlex>{{ file.fileName }}</span>
        </div>

        <div fxLayout="row" fxLayoutAlign="start" fxFlex="1 0 auto" class="w-100p">
          <div fxLayout="column" fxLayoutAlign="start" fxFlex="76">
            <div fxLayout="row wrap" fxLayoutAlign="start" fxFlex="1 0 auto" class="w-100p m-12">
              <a target="_blank" [href]="file.s3url" [download]="file.fileName" fxFlex *ngIf="isFileTypeImage(file.fileName)">
                <img [src]="file.s3url" alt="" class="p-1" *ngIf="isFileTypeImage(file.fileName)" />
              </a>
              <app-hls-player
                class="video-thumb w-100p"
                *ngIf="isItemVideo(file.fileName)"
                [options]="{
                  autoplay: false,
                  controls: true,
                  preload: 'auto',
                  muted: false,
                  poster: file.coverImage,
                  html5: { hls: { overrideNative: true, withCredentials: false } },
                  sources: [{ src: file.s3url, type: getFileTypeForMultimedia(file.s3url) }]
                }"
              >
              </app-hls-player>
              <audio controls fxFlex *ngIf="isItemAudio(file.fileName)" class="p-1 audio-thumb">
                <source [src]="file.s3url" [type]="getFileTypeForMultimedia(file.fileName)" />
              </audio>
              <a target="_blank" [href]="file.s3url" [download]="file.fileName" fxFlex *ngIf="isFileTypePDF(file.fileName)">
                <img src="assets/images/filetype/pdf.png" />
              </a>
              <a target="_blank" [href]="file.s3url" [download]="file.fileName" fxFlex *ngIf="isFileTypeOther(file.fileName)">
                <img style="width: 100%; height: 70px" src="https://passets.wajooba.com/img/other_Download.svg" />
              </a>
            </div>
          </div>
          <div fxLayout="column" fxLayoutAlign="start" fxFlex="20">
            <div fxLayout="row wrap" fxLayoutAlign="end">
              <button type="button" *ngIf="isItemVideo(file.fileName) && file.status != 'COMPLETED'" mat-icon-button matSuffix class="pointer" (click)="getAssestById(file.id)">
                <mat-icon>refresh</mat-icon>
              </button>
              <a mat-icon-button matSuffix target="_blank" class="d-c pointer" [href]="file.downloadUrl ? file.downloadUrl : file.s3url" [download]="file.fileName">
                <mat-icon class="br-blue">cloud_download</mat-icon>
              </a>
              <button mat-icon-button type="button" matSuffix class="pointer" *ngIf="isDeleteAllowed" [disabled]="!isMasterTenant && isFranchiseCourse" (click)="deleteAssest(file.id)">
                <mat-icon class="br-red">delete</mat-icon>
              </button>
            </div>
          </div>
        </div>

        <div fxLayout="row" fxLayoutAlign="start" class="m-12" *ngIf="isItemVideo(file.fileName) && file.status == 'COMPLETED'">
          <span class="title-text br-green" fxFlex>Video is ready to use, Don't forget to click Submit and then publish the video. </span>
        </div>

        <div fxLayout="row" fxLayoutAlign="start" class="m-12" *ngIf="isItemVideo(file.fileName) && (file.status == '500' || file.status == 'IN_PROGRESS')">
          <span class="title-text br-green" fxFlex
            >Video conversion to streaming format started.. based on size it will take some time. You can switch to other screens, Do not forget to click Submit</span
          >,
        </div>

        <div fxLayout="row" fxLayoutAlign="start" class="m-12" *ngIf="isItemVideo(file.fileName) && (file.status == 'FAILED' || file.status == 'STATUS_FAILED')">
          <span class="title-text br-red" fxFlex>Error converting video to streaming format.</span>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="isNewlook" style="width: 100%">
    <div fxLayout="row" fxLayoutAlign="center" class="m-12 w-100p" style="width: 100%; margin-left: 3px !important; padding-right: 3px" *ngFor="let file of files">
      <div *ngIf="file.fileName" class="mat-card" style="width: 100%; margin-right: 2px !important">
        <div>
          <div class="rowcardactivity" fxLayout="column" fxLayout.gt-xs="row" fxLayoutAlign="start" fxFlex="100">
            <div fxLayout="column" fxLayoutAlign="start" fxFlex.gt-xs="10" fxFlex.gt-sm="10" fxFlex="100">
              <div class="imgactivitycss" [ngClass]="getLessionType(file)"></div>
            </div>
            <div fxLayout="column" fxFlex.gt-xs="90" fxFlex.gt-sm="90" fxFlex="100" style="align-items: flex-start; justify-content: center; display: flex">
              <h3 class="m-0 rowtitleactivity" style="margin-left: 25px !important">
                {{ file.fileName }}
              </h3>
            </div>
          </div>

          <div *ngIf="file.fileName" fxLayout="column" fxLayoutAlign="end end" style="width: 100%" class="w-100p">
            <div fxLayout="row" fxLayoutAlign="start" class="m-12">
              <span *ngIf="isStudentFile" style="justify-content: center; align-items: center; display: flex; margin-right: 10px"
                >{{ file.ownerName | titlecase }} - {{ file.updatedAt | date : "MMM dd, YYYY" : "UTC" }}</span
              >

              <button type="button" *ngIf="isItemVideo(file.fileName) && file.status != 'COMPLETED'" mat-icon-button matSuffix class="pointer" (click)="getAssestById(file.id)">
                <mat-icon>refresh</mat-icon>
              </button>
              <a mat-icon-button matSuffix target="_blank" class="d-c pointer" [href]="file.downloadUrl ? file.downloadUrl : file.s3url" [download]="file.fileName">
                <mat-icon class="br-blue">cloud_download</mat-icon>
              </a>
              <button mat-icon-button type="button" matSuffix class="pointer" *ngIf="isDeleteAllowed" [disabled]="!isMasterTenant && isFranchiseCourse" (click)="deleteAssest(file.id)">
                <mat-icon class="br-red">delete</mat-icon>
              </button>
            </div>
          </div>
        </div>

        <div *ngIf="isFileTypeImage(file.fileName) || isItemVideo(file.fileName) || isItemAudio(file.fileName)" fxLayout="row" fxLayoutAlign="start" class="m-12" style="margin-left: 10px !important">
          <a target="_blank" [href]="file.s3url" [download]="file.fileName" fxFlex *ngIf="isFileTypeImage(file.fileName)">
            <img [src]="file.s3url" alt="" class="p-1" *ngIf="isFileTypeImage(file.fileName)" />
          </a>
          <app-hls-player
            class="video-thumb w-100p"
            *ngIf="isItemVideo(file.fileName)"
            [options]="{
              autoplay: false,
              controls: true,
              preload: 'auto',
              muted: false,
              poster: file.coverImage,
              html5: { hls: { overrideNative: true, withCredentials: false } },
              sources: [{ src: file.s3url, type: getFileTypeForMultimedia(file.s3url) }]
            }"
          >
          </app-hls-player>
          <audio controls fxFlex *ngIf="isItemAudio(file.fileName)" class="p-1 audio-thumb">
            <source [src]="file.s3url" [type]="getFileTypeForMultimedia(file.fileName)" />
          </audio>
        </div>
        <div *ngIf="isItemVideo(file.fileName) && file.status == 'COMPLETED'" fxLayout="row" fxLayoutAlign="start" class="m-12">
          <span class="title-text br-green" fxFlex>Video is ready to use, Don't forget to click Submit and then publish the video. </span>
        </div>

        <div *ngIf="isItemVideo(file.fileName) && (file.status == '500' || file.status == 'IN_PROGRESS')" fxLayout="row" fxLayoutAlign="start" class="m-12">
          <span class="title-text br-green" fxFlex
            >Video conversion to streaming format started.. based on size it will take some time. You can switch to other screens, Do not forget to click Submit</span
          >,
        </div>

        <div *ngIf="isItemVideo(file.fileName) && (file.status == 'FAILED' || file.status == 'STATUS_FAILED')" fxLayout="row" fxLayoutAlign="start" class="m-12">
          <span class="title-text br-red" fxFlex>Error converting video to streaming format.</span>
        </div>
      </div>
    </div>
  </div>
</div>
